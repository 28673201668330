import { classNames } from "../../utils/Utitlities";

const DrawerItemView = ({
  Name,
  onClick,
  topRightRounded,
  bottomRightRounded,
  selected,
  icon,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        selected ? " bg-gray-700 " : "  bg-gray-900 ",
        topRightRounded ? "rounded-tr-xl" : "",
        bottomRightRounded ? "rounded-br-xl" : "",
        "w-[100%] align-middle justify-center   cursor-pointer flex flex-col group  items-center m-0 p-4  "
      )}
    >
      {icon}
      <p
        className={classNames(
          selected ? "text-white" : "text-gray-400",
          " text-[0.65rem] group-hover:text-white   "
        )}
      >
        {" "}
        {Name}{" "}
      </p>
    </div>
  );
};
export default DrawerItemView;
