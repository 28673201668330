import { Dialog, Tab, Transition } from "@headlessui/react";
import Prism from "prismjs";
import React, { Fragment, useEffect, useState } from "react";
import { getAppCredentials } from "../../network/Network";
import { classNames, getIdentifierFromUrl } from "../../utils/Utitlities";
import CodeBlock from "../CodeBlock";

import QRCode from "react-qr-code";
const tabsNames = [
  {
    name: "QR code",
    type: "qrcode",
  },
  {
    name: "New Apps",
    type: "new",
  },
  {
    name: "Exisiting Apps",
    type: "existing",
  },
];
const DisplayContentBasedOnType = ({ type, appCredntialsInfo }) => {
  switch (type) {
    case "new":
      return <NewAppInstructions appCredntialsInfo={appCredntialsInfo} />;

    case "existing":
      return <ExistingAppInstructions appCredntialsInfo={appCredntialsInfo} />;
    case "qrcode":
      return (
        <RenderQrCode
          shareable={appCredntialsInfo?.shareable}
          appLink={appCredntialsInfo?.apk_link}
        />
      );

    default:
      break;
  }
};
const RenderQrCode = ({ shareable, appLink }) => {
  return (
    <div className="flex h-[70vh] mt-2 items-center flex-col ">
      <div className="mb-10 flex flex-wrap  ">
        <p className="text-sm ">Download and install Nano android app from</p>
        
        <a  href={appLink} className="text-sm underline  mx-1 text-blue-600 cursor-pointer " >here</a>
        <p className="text-sm ">. Next go to</p>
        <p className="text-sm font-bold  mx-1 ">{"Settings > Developers"}</p>
        <p className="text-sm ">
          and scan this QR code to run this app on your phone.
        </p>
      </div>

      <br />

      {shareable != null && (
        <QRCode
          size={228}
          style={{
            height: "auto",
            maxWidth: "100%",
            width: "200px",
            alignSelf: "center",
          }}
          value={shareable}
          viewBox={`0 0 256 256`}
        />
      )}
      <br />
    </div>
  );
};

const NewAppInstructions = ({ appCredntialsInfo }) => {
  return (
    <div className="mt-2 h-[70vh]  ">
      <p className="text-sm ">
        When the app is published, you can run below command to create project
        and run in your local machine
      </p>

      <CodeBlock language={"language-bash"}>
        npx rn-nano init {appCredntialsInfo?.name}
      </CodeBlock>
      <p className="text-sm ">
        While project is being created, choose "
        <b>Connect to existing project at Nanoapp.dev</b>" by selecting option{" "}
        <b>(b)</b>. And enter the following
      </p>
      <br />
      <Dialog.Title
        as="h3"
        className="text-sm font-medium leading-6 text-gray-900"
      >
        AppId :
      </Dialog.Title>
      <CodeBlock language={"language-javascript"}>
        {appCredntialsInfo?.client_id}
      </CodeBlock>
      <Dialog.Title
        as="h3"
        className="text-sm font-medium leading-6 text-gray-900"
      >
        AppSecret :
      </Dialog.Title>
      {/* <p className="text-sm font-bold text-black">AppSecret :</p> */}
      <CodeBlock language={"language-javascript"}>
        {appCredntialsInfo?.client_secret}
      </CodeBlock>
      <Dialog.Title
        as="h3"
        className="text-sm font-medium leading-6 text-gray-900"
      >
        AppUrl :
      </Dialog.Title>
      {/* <p className="text-sm font-bold text-black"></p> */}
      <CodeBlock language={"language-javascript"}>
        {appCredntialsInfo?.app_url}
      </CodeBlock>
      <p className="text-sm ">Run your app by</p>

      <CodeBlock language={"language-bash"}>npm start</CodeBlock>

      <br />
    </div>
  );
};
const ExistingAppInstructions = ({ appCredntialsInfo }) => {
  return (
    <div className="mt-2 h-[70vh] ">
      <p className="text-sm ">
        When the app is published, if you have an already existing project
        replace below lines in <b>nano.config.js</b>
      </p>

      <CodeBlock language={"language-javascript"}>
        export const APP_URL = '{appCredntialsInfo?.app_url}'; export const
        CLIENT_ID = '{appCredntialsInfo?.client_id}'; export const CLIENT_SECRET
        = '{appCredntialsInfo?.client_secret}';
      </CodeBlock>

      <p className="text-sm ">Run your app by</p>

      <CodeBlock language={"language-bash"}>npm start</CodeBlock>
    </div>
  );
};
function Instructions({ isInstructionsModalOpen, closeInstructionsModal }) {
  const [appCredntialsInfo, setAppCredntialsInfo] = useState(null);
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    const identifier = getIdentifierFromUrl();
    getAppCredentials(identifier)
      .then((re) => {
        setAppCredntialsInfo(re);
      })
      .catch(() => {});
  }
  useEffect(() => {
    if (isInstructionsModalOpen) {
      afterOpenModal();
      setTimeout(() => {
        Prism.highlightAll();
      }, 2000);
    }
  }, [isInstructionsModalOpen]);

  return (
    <Transition appear show={isInstructionsModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={closeInstructionsModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed  inset-0 ">
          <div className="flex min-h-full items-center justify-center p-4 text-center  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full   max-w-xl transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Tab.Group>
                  <Tab.List className="flex space-x-1 outline-none bg-npurple rounded-xl bg-blue-900/20 p-1">
                    {tabsNames.map((category) => (
                      <Tab
                        key={category.name}
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                            "",
                            selected
                              ? "bg-white text-blue-700 shadow"
                              : "text-blue-100  hover:bg-white/[0.12] hover:text-white"
                          )
                        }
                        onClick={() => {
                          // onAssetCategoryPress(category.name);
                        }}
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels
                    // onScroll={onAssetsScroll}
                    className="mt-2 h-[65vh] overflow-y-scroll "
                  >
                    {tabsNames.map((posts, idx) => (
                      <Tab.Panel
                        key={idx}
                        className={classNames(
                          "rounded-xl bg-white p-3 pt-0",
                          "ring-white/60    ring-offset-2 h-full focus:outline-none focus:ring-2"
                        )}
                      >
                        <DisplayContentBasedOnType
                          appCredntialsInfo={appCredntialsInfo}
                          type={posts.type}
                        />
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Instructions;
