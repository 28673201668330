import { Dialog, Tab, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { BiImageAdd, BiMovie, BiPlus } from "react-icons/bi";
import { useFilePicker } from "use-file-picker";
import { getAllAssets, uploadAssets } from "../../network/Network";
import {
  checkAlphaNumDash,
  classNames,
  getIdentifierFromUrl,
} from "../../utils/Utitlities";
import {
  useClickAssetDetails,
  useShouldReloadAssetsData,
  useShouldShowAppLogoCropViewerModal,
  useShouldShowAssetsDetailsModal,
  useShowDeleteAssetModal,
  useShowMediaModal,
} from "../Zustand";
import AssetItemView from "../itemviews/AssetItemView";
import AssetDetailsModal from "./AssetsDetailModal";
import DeleteAssetModal from "./DeleteAsset";
import FontsItemView from "../itemviews/FontsItemView";
// import SvgInline from "../itemviews/IconSvgItemView";
import SvgInline from "../itemviews/IconSvgItemView";
import AppLogoCropViewerModal from "./AppLogoCropViewerModal";

const tabsNames = [
  {
    name: "Media",
  },
  {
    name: "App Logo",
  },
  {
    name: "Icons",
  },
  {
    name: "Fonts",
  },
];

const getFileExtensionBasedOnAssetType = (assetType) => {
  switch (assetType) {
    case "Media":
      return [".jpg, .jpeg, .png", ".svg"];
    case "App Logo":
      return [".jpg, .jpeg, .png", ".svg"];

    case "Icons":
      return [".svg"];

    case "Fonts":
      return [".ttf"];

    default:
      return [".jpg, .jpeg, .png", ".svg"];
  }
};
const convertFontFiletoBase64 = async (file) => {
  const result_base64 = await new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
  return result_base64;
};

const MediaModal = ({ identifier, appName }) => {
  const [assetsList, setAssetsList] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadingFilesArray, setUploadingFilesArray] = useState([]);
  const assetTypeRef = useRef(null);
  const pageNumRef = useRef(1);
  const canFetchNextPageRef = useRef(false);
  const { openFilePicker, plainFiles, clear } = useFilePicker({
    accept: getFileExtensionBasedOnAssetType(assetTypeRef.current),
    readAs: "DataURL",
    onClear: () => {},
    multiple: assetTypeRef.current === "App Logo" ? false : true,
  });

  const showMediaModal = useShowMediaModal((state) => state.showMediaModal);
  const setShowMediaModel = useShowMediaModal(
    (state) => state.setShowMediaModel
  );
  // const showDeleteAssetModal = useShowDeleteAssetModal(
  //   (state) => state.showDeleteAssetModal
  // );
  const setShowDeleteAssetModel = useShowDeleteAssetModal(
    (state) => state.setShowDeleteAssetModel
  );
  const shouldReloadAssetsData = useShouldReloadAssetsData(
    (state) => state.shouldReloadAssetsData
  );
  const setShouldReloadAssetsData = useShowDeleteAssetModal(
    (state) => state.setShouldReloadAssetsData
  );
  const setShouldShowAssetsDetailModal = useShouldShowAssetsDetailsModal(
    (state) => state.setShouldShowAssetsDetailModal
  );
  const setClickedAssetDetails = useClickAssetDetails(
    (state) => state.setClickedAssetDetails
  );

  const shouldShowAppLogoCropViewerModal = useShouldShowAppLogoCropViewerModal(
    (state) => state.shouldShowAppLogoCropViewerModal
  );
  const setShouldShowAppLogoCropViewerModal =
    useShouldShowAppLogoCropViewerModal(
      (state) => state.setShouldShowAppLogoCropViewerModal
    );
  const onMediaModalClose = () => {
    setShowMediaModel(false);
  };
  const fetchAssets = (asset_types) => {
    const urlIdentifier = getIdentifierFromUrl();
    getAllAssets({
      identifier: urlIdentifier,
      asset_types,
      pageNum: pageNumRef.current,
    })
      .then((d) => {
        if (d != null && d["results"] !== null) {
          if (pageNumRef.current > 1) {
            setAssetsList((val) => [...val, ...d["results"]]);
          } else {
            setAssetsList(d["results"]);
          }
          if (d["next"] !== null) {
            canFetchNextPageRef.current = true;
          } else {
            canFetchNextPageRef.current = false;
          }
        }
        clear();
      })
      .catch(() => {
        // canFetchNextPageRef.current = false;
      });
  };
  const fetchAssetsBasedOnAssetType = () => {
    switch (assetTypeRef.current) {
      case "Media":
        fetchAssets("images,videos,audios");

        break;
      case "App Logo":
        fetchAssets("launcher_icons");

        break;
      case "Icons":
        fetchAssets("icons");

        break;
      case "Fonts":
        fetchAssets("fonts");

        break;

      default:
        fetchAssets("images");

        break;
    }
  };
  useEffect(() => {
    const urlIdentifier = getIdentifierFromUrl();
    if (urlIdentifier && checkAlphaNumDash(urlIdentifier + "")) {
      fetchAssetsBasedOnAssetType();
    }
  }, [showMediaModal, shouldReloadAssetsData]);
  const onFileUploadClick = () => {
    // openFilePicker();
    openFilePicker();
  };
  const onUploadProgress = (uploadedPercentageValue) => {
    setUploadProgress(uploadedPercentageValue);
  };
  const uploadFiles = (asset_type, file, cropArea = null) => {
    const urlIdentifier = getIdentifierFromUrl();
    setUploadingFile(file);

    uploadAssets({
      identifier: urlIdentifier,
      asset_type,
      file,
      onUploadProgress: onUploadProgress,
      cropArea,
    })
      .then((d) => {
        fetchAssets(asset_type);
        setUploadingFile(null);
        setUploadProgress(null);
        const tempUploadFilesArray = [...uploadingFilesArray];
        const res = tempUploadFilesArray.shift();
        if (res != null) {
          setUploadingFilesArray(tempUploadFilesArray);
        }
        clearFiles();
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (uploadingFilesArray != null && uploadingFilesArray.length > 0)
      switch (assetTypeRef.current) {
        case "Media":
          uploadFiles("images", uploadingFilesArray[0]);

          break;
        case "App Logo":
          setShouldShowAppLogoCropViewerModal(uploadingFilesArray[0]);

          break;
        case "Icons":
          uploadFiles("icons", uploadingFilesArray[0]);

          break;
        case "Fonts":
          uploadFiles("fonts", uploadingFilesArray[0]);
          break;

        default:
          break;
      }
  }, [uploadingFilesArray]);
  useEffect(() => {
    if (plainFiles != null && plainFiles.length > 0) {
      setUploadingFilesArray(plainFiles);
    } else {
    }
  }, [plainFiles]);
  const clearFiles = () => {
    clear();
  };
  useEffect(() => {
    return () => {
      pageNumRef.current = 1;
    };
  }, []);
  const onAssetCategoryPress = (cat) => {
    assetTypeRef.current = cat;
    pageNumRef.current = 1;
    switch (cat) {
      case "Media":
        fetchAssets("images,videos,audios");

        break;
      case "App Logo":
        fetchAssets("launcher_icons");

        break;
      case "Icons":
        fetchAssets("icons");

        break;
      case "Fonts":
        fetchAssets("fonts");

        break;

      default:
        break;
    }
  };
  const onUploadAssetPress = (cat) => {
    assetTypeRef.current = cat;

    openFilePicker();
  };
  const onDeleteAssetClick = (clickedAsset) => {
    setShowDeleteAssetModel(clickedAsset);
  };
  const testValue = uploadProgress != null ? uploadProgress + "%" : "10%";
  const getEmptyAssetRemarkByAssetType = (assetType) => {
    switch (assetType) {
      case "Media":
        return "Add Media to see them here";

      case "App Logo":
        return "Upload app icon to display here";

      case "Icons":
        return "Add Media to see them here";

      case "Fonts":
        return "No fonts added";

      default:
        return "";
    }
  };

  const getIconByAssetType = (assetType) => {
    switch (assetType) {
      case "Media":
        return <BiMovie className="mb-4" size={65} color="gray" />;

      case "App Logo":
        return <BiImageAdd className="mb-4" size={65} color="gray" />;

      case "Icons":
        return <BiImageAdd className="mb-4" size={65} color="gray" />;

      case "Fonts":
        return <BiImageAdd className="mb-4" size={65} color="gray" />;

      default:
        return "";
    }
  };
  const onAssetClick = (assetData) => {
    setClickedAssetDetails(assetData);
    setShouldShowAssetsDetailModal(true);
  };
  const onUploadLauncherIconPress = (image, cropArea) => {
    uploadFiles("launcher_icons", image, cropArea);
  };
  const onAssetsScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;

    // e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && canFetchNextPageRef.current) {
      const cur = pageNumRef.current;
      pageNumRef.current = cur + 1;
      fetchAssetsBasedOnAssetType();
    }
  };
  return (
    <Transition appear show={showMediaModal} as={Fragment}>
      <Dialog
        static={true}
        as="div"
        className="relative z-10"
        onClose={onMediaModalClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed  inset-0 ">
          <div className="flex min-h-full items-center justify-center p-4 text-center  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-1/2  transform  rounded-2xl bg-white p-6 text-left  shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl mb-2 flex font-medium leading-6 text-npurple"
                >
                  {appName}{" "}
                  <p className="ml-2 text-lg leading-6 text-black "> assets</p>
                </Dialog.Title>
                {/* <div className="h-72 bg-red-300 bg-gradient-to-r from-purple-600 to-blue-600"></div> */}
                <div className="   px-2 py-2 sm:px-0">
                  <Tab.Group>
                    <Tab.List className="flex space-x-1 bg-npurple rounded-xl bg-blue-900/20 p-1">
                      {tabsNames.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                              "",
                              selected
                                ? "bg-white text-blue-700 shadow"
                                : "text-blue-100  hover:bg-white/[0.12] hover:text-white"
                            )
                          }
                          onClick={() => {
                            onAssetCategoryPress(category.name);
                          }}
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels
                      onScroll={onAssetsScroll}
                      className="mt-2 h-[65vh] overflow-y-scroll "
                    >
                      {tabsNames.map((posts, idx) => (
                        <Tab.Panel
                          key={idx}
                          className={classNames(
                            "rounded-xl bg-white p-3 pt-0",
                            "ring-white/60   ring-offset-2 h-full focus:outline-none focus:ring-2"
                          )}
                        >
                          <div className="flex justify-between mb-2 mt-2  items-center ">
                            <p>{posts.name} </p>
                            <button
                              onClick={() => {
                                onUploadAssetPress(posts.name);
                              }}
                              className="items-center relative flex rounded-sm bg-nblue text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                            >
                              <span className="absolute -inset-1.5" />
                              <span className="sr-only">
                                View notifications
                              </span>

                              <BiPlus
                                size={25}
                                color={"white"}
                                className="pl-2"
                              />

                              <p
                                className={
                                  "text-white hover:bg-gray-700 hover:text-white rounded-md pl-1 pr-3 py-2 text-sm font-medium"
                                }
                                aria-current={"page"}
                              >
                                {"Add"}
                              </p>
                            </button>
                          </div>
                          {(uploadingFile != null && uploadProgress != null) ||
                          (assetsList != null && assetsList.length > 0) ? (
                            // <div className="bg-blue-300 flex justify-center  " >
                            <ul
                              className="flex w-[576px] flex-wrap  "

                              // className="grid grid-cols-4 gap-4 "
                            >
                              {uploadingFile != null &&
                                uploadProgress != null && (
                                  <div
                                    className="relative bg-gray-300"
                                    key={uploadingFile.id}
                                  >
                                    <AssetItemView
                                      onDeleteClick={onDeleteAssetClick}
                                      item={uploadingFile}
                                    />
                                    <div
                                      style={{ width: testValue }}
                                      className={`bg-blue-400 left-[10%] top-1/2  -translate-y-1/2 rounded-lg  bottom-0 h-2 max-w-[80%]  self-center align-middle  absolute`}
                                    ></div>
                                  </div>
                                )}
                              {assetsList != null &&
                                assetsList.length > 0 &&
                                assetsList.map((post) => {
                                  if (
                                    post.type != null &&
                                    post.type.includes("ttf")
                                  ) {
                                    return (
                                      <FontsItemView
                                        key={post.id}
                                        onDeleteClick={onDeleteAssetClick}
                                        item={post}
                                        onAssetClick={onAssetClick}
                                      />
                                    );
                                  }
                                  if (
                                    post.type != null &&
                                    post.type.includes("svg")
                                  ) {
                                    return (
                                      <SvgInline
                                        key={post.id}
                                        url={post.file}
                                        height={100}
                                        width={100}
                                        onDeleteClick={onDeleteAssetClick}
                                        item={post}
                                        onAssetClick={onAssetClick}
                                      />
                                    );
                                  }
                                  return (
                                    <AssetItemView
                                      onDeleteClick={onDeleteAssetClick}
                                      key={post.id}
                                      item={post}
                                      onAssetClick={onAssetClick}
                                    />
                                  );
                                })}
                            </ul>
                          ) : (
                            // </div>
                            <div className=" h-full justify-center flex flex-col items-center  ">
                              {getIconByAssetType(assetTypeRef.current)}
                              <p onClick={onFileUploadClick}>
                                {getEmptyAssetRemarkByAssetType(
                                  assetTypeRef.current
                                )}{" "}
                              </p>
                            </div>
                          )}
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>

          <AssetDetailsModal />
          <DeleteAssetModal refresh={fetchAssetsBasedOnAssetType} />
          <AppLogoCropViewerModal
            appName={appName}
            onUploadLauncherIconPress={onUploadLauncherIconPress}
          />
        </div>
      </Dialog>
    </Transition>
  );
};

export default MediaModal;
