import React from "react";
const AppFrame = ({ totalCode }) => {
  return (
    <div className="h-[70vh]  " >
      <div
        className="position-relative relative  "
        style={{ height: "600px", width: "380px" }}
      >
        <div
          style={{
            //   background: `url('https://picsum.photos/300/300')`,
            background: "url(" + require("../../assets/mobile_phone.png") + ")",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            //   height: "100%",
          }}
          className="position-absolute absolute "
        ></div>
        <div
          style={{
            top: "40px",
            left: "70px",
            right: "70px",
            bottom: "40px",
          }}
          className="position-absolute absolute"
        >
          <iframe
            name="preview"
            title="totalapp_Nano-iframe"
            id="total_app_iframe"
            src="https://www.nanoapp.dev/dashboard/preview"
            frameBorder="0"
            style={{ height: "100%", width: "100%", borderRadius: "30px" }}
            onLoad={() => {
              const iframe = document.getElementById("total_app_iframe");
              if (iframe != null && iframe.contentWindow != null) {
                if (totalCode) {
                  iframe.contentWindow.postMessage(
                    totalCode,
                    "https://www.nanoapp.dev/dashboard/preview"
                  );
                }
              }
            }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default AppFrame;
