import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import {
  BiMenu as Bars3Icon,
  BiChevronDown,
  BiPlay,
  BiPlus,
  BiX as XMarkIcon,
} from "react-icons/bi";
import InputField from "./InputField";

import { CODE_STORE } from "../utils/Constants";
import {
  checkAlphaNumDash,
  classNames,
  getIdentifierFromUrl,
  replaceUrlByProjectIdentifier,
} from "../utils/Utitlities";
import { LoadingIndicator } from "./LoadingIndicator";
import {
  useAppIdentifer,
  useCodeInfo,
  useCodeSaveStatus,
  useIsLoggedIn,
  useRunTotalAppInfo,
  useScreensList,
  useSetProjectName,
  useShowLogInPopup,
  useShowLogoutModal,
  useShowMediaModal,
  useUserInfo,
} from "./Zustand";
import { getScreenCode } from "../network/Network";

const leftNavigation = [
  { name: "input", href: "#", current: false },
  // { name: "Assets", href: "#", current: false },
];

export default function Header({
  onPublishPress,
  onMyAppsPress,

  onSharePress,

  onRunPress,
  setCodeInRunTotalAppInfo,
  saveCodeWithUpdatedProjectName,
}) {
  const projectName = useSetProjectName((state) => state.projectName);
  const setProjectName = useSetProjectName((state) => state.setProjectName);
  const codeSaveStatus = useCodeSaveStatus((state) => state.codeSaveStatus);

  const userInfo = useUserInfo((state) => state.userInfo);
  const setAppIdentifer = useAppIdentifer((state) => state.setAppIdentifer);

  const codeInfo = useCodeInfo((state) => state.codeInfo);

  const isLoggedIn = useIsLoggedIn((state) => state.isLoggedIn);
  const setIsLoggedIn = useIsLoggedIn((state) => state.setIsLoggedIn);
  const setScreensList = useScreensList((state) => state.setScreensList);

  const setRunTotalAppInfo = useRunTotalAppInfo(
    (state) => state.setRunTotalAppInfo
  );
  const setShowLogoutModal = useShowLogoutModal(
    (state) => state.setShowLogoutModal
  );
  const setShowMediaModel = useShowMediaModal(
    (state) => state.setShowMediaModel
  );
  const setShowLoginPopup = useShowLogInPopup(
    (state) => state.setShowLoginPopup
  );
  const [isNewAppCreating, setIsNewAppCreating] = useState(false);
  const onLogOutPress = () => {
    setShowLogoutModal(true);

  };

  const onRunAppPress = () => {
    const urlIdentifier = getIdentifierFromUrl();
    if (urlIdentifier && checkAlphaNumDash(urlIdentifier + "")) {
      setRunTotalAppInfo(urlIdentifier);
    } else {
      setCodeInRunTotalAppInfo();
    }
  };
  const onChangeProjectName = (e) => {
    const regex = /[^a-zA-Z]+/g;
    const string = e.target.value;

    const newString = string.replace(regex, "");

    setProjectName(newString);
  };
  const onProjectNameInputFieldBlur = (val) => {
    saveCodeWithUpdatedProjectName(val);
  };
  const onProfileIconPress = () => {
    setShowLoginPopup(true);
  };
  useEffect(() => {}, []);
  const onNewAppPress = () => {
    if (isLoggedIn) {
      setIsNewAppCreating(true);

      setScreensList([]);
      getScreenCode({ is_new: true })
        .then((r) => {
          if (r.identifier != null) {
            replaceUrlByProjectIdentifier(r.identifier);
            setAppIdentifer(r.identifier);
          }
          setIsNewAppCreating(false);
        })
        .catch((e) => {
          console.log("header new screen create error", e);
        });
    } else {
      setShowLoginPopup(true);
    }
  };
  return (
    <Disclosure
      as="nav"
      className="bg-gray-800 
     "
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl  px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1  items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href={window.location.origin}>
                    <img
                      className="h-5 w-auto"
                      src={require("../assets/logo.svg").default}
                      alt="Nano logo"
                    />
                  </a>
                </div>

                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {leftNavigation.map((item) => {
                      if (item.name == "input") {
                        return (
                          <InputField
                            value={projectName}
                            onChange={onChangeProjectName}
                            onProjectNameInputFieldBlur={
                              onProjectNameInputFieldBlur
                            }
                          />
                        );
                      }
                      return (
                        <a
                          key={item.name}
                          href={item.href}
                          onClick={
                            item.name == "My Apps" ? onMyAppsPress : null
                          }
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      );
                    })}
                    <div
                      onClick={onMyAppsPress}
                      className=" cursor-pointer flex rounded-sm flex-row items-center w-8  justify-center  border-gray-700  border-[1px]  "
                    >
                      {/* <img
                        className="inline-block h-8 w-8 mr-2 rounded-sm"
                        src="https://w7.pngwing.com/pngs/574/270/png-transparent-android-figurine-illustration-android-application-software-android-logo-logo-mobile-app-development-android-software-development.png"
                        alt=""
                      /> */}

                      <BiChevronDown className="w-4" size={20} color="white" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {isLoggedIn && (
                  <p
                    className={
                      "text-ngreen  rounded-md px-3 py-2 text-xs font-medium"
                    }
                    aria-current={"page"}
                  >
                    {codeSaveStatus}
                  </p>
                )}

                <Menu as="div" className=" relative ml-3 max-sm:hidden ">
                  <div>
                    <Menu.Button
                      onClick={onNewAppPress}
                      className="items-center relative flex rounded-sm bg-npurple text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      {isNewAppCreating ? (
                        <LoadingIndicator />
                      ) : (
                        <BiPlus size={25} color={"white"} className="pl-2" />
                      )}

                      <p
                        className={
                          "text-white hover:bg-gray-700 hover:text-white rounded-md pl-1 pr-3 py-2 text-sm font-medium"
                        }
                        aria-current={"page"}
                      >
                        {"New App"}
                      </p>
                    </Menu.Button>
                  </div>
                </Menu>
                <Menu as="div" className=" relative ml-3 ">
                  <div>
                    <Menu.Button className="items-center  relative flex rounded-sm bg-ngreen  text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BiPlay size={30} color={"white"} className="pl-2" />
                      <p
                        className={
                          "text-white hover:bg-gray-700 hover:text-white rounded-md pr-3 py-2 text-sm font-medium"
                        }
                        aria-current={"page"}
                      >
                        {"Run"}
                      </p>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                            onClick={onRunAppPress}
                          >
                            Run App
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                            onClick={onRunPress}
                          >
                            Run Screen
                          </a>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                            onClick={() => {
                              onPublishPress(CODE_STORE.DRAFT);
                            }}
                          >
                            Save
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                            onClick={() => {
                              onPublishPress(CODE_STORE.PUBLISH);
                            }}
                          >
                            Publish
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                            onClick={onSharePress}
                          >
                            Share
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>

                <Menu
                  as="div"
                  className="hidden relative ml-3 sm:ml-6 sm:block"
                >
                  <div>
                    <Menu.Button className=" relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={
                          isLoggedIn
                            ? userInfo?.picture
                            : require("../assets/user_thumbnail.jpeg")
                        }
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer "
                            )}
                            onClick={
                              isLoggedIn ? onLogOutPress : onProfileIconPress
                            }
                          >
                            {isLoggedIn ? "Log out" : "Sign in"}
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {leftNavigation.map((item) => {
                if (item.name == "input") {
                  return (
                    <InputField
                      value={projectName}
                      onChange={onChangeProjectName}
                    />
                  );
                }
                return (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                  
                    onClick={item.name == "My Apps" ? onMyAppsPress : null}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                );
              })}

              <Disclosure.Button
                className={classNames(
                  "text-gray-300 hover:bg-gray-700 hover:text-white",
                  "block rounded-md px-3 py-2 text-base font-medium flex bg-nblue items-center "
                )}
                onClick={onNewAppPress}
              >
                <BiPlus size={25} color={"white"} className="pl-2" />
                <p
                  className={
                    "text-white hover:bg-gray-700 hover:text-white rounded-md pl-1 pr-3 py-2 text-sm font-medium"
                  }
                  aria-current={"page"}
                >
                  {"New"}
                </p>
              </Disclosure.Button>

              <Disclosure.Button
                className={classNames(
                  "text-gray-300 hover:bg-gray-700 hover:text-white",
                  "block rounded-md px-3 py-2 text-base font-medium flex items-center "
                )}
                onClick={isLoggedIn ? onLogOutPress : onProfileIconPress}
              >
                <img
                  className="h-8 w-8 rounded-full mr-2"
                  src={
                    isLoggedIn
                      ? userInfo?.picture
                      : require("../assets/user_thumbnail.jpeg")
                  }
                  alt=""
                />
                <div>
                  <p className="text-lg capitalize ">
                    {isLoggedIn ? userInfo?.name : "Sign in"}
                  </p>
                  <p className="text-xs text-left text-gray-400 ">
                    {isLoggedIn ? "Log out" : ""}
                  </p>
                </div>
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
