function extractJsFunctions(code) {
  const functionRegex = /function\s+(\w+)\s*\([^)]*\)\s*{[\s\S]*?}/g;
  const arrowFunctionRegex =
    /(?:(?:const|let|var)\s+)?(\w+)\s*=\s*(?:\([^)]*\)\s*=>|function\s*\([^)]*\))\s*{[\s\S]*?}/g;

  const functions = [];
  let match;

  // Extract traditional functions
  while ((match = functionRegex.exec(code)) !== null) {
    functions.push({ code: match[0], name: match[1] });
  }

  // Extract arrow functions
  while ((match = arrowFunctionRegex.exec(code)) !== null) {
    functions.push({ code: match[0], name: match[1] });
  }

  return functions;
}

function modifyOnlyOnFunctions(inputString, allCustomFunctionsString) {
  // Regex to match function definitions starting with 'on'
  const functionRegex = /(on\w+):\s*(\([^)]*\))\s*=>\s*(\{[^}]*\})/g;

  return inputString.replace(functionRegex, (match, funcName, params, body) => {
    // Remove whitespace from the beginning and end of the body
    const trimmedBody = body.trim().slice(1, -1).trim();

    // Create the modified function string
    const modifiedFunction = `${funcName}: ${params} => {
      ${allCustomFunctionsString}
      ${trimmedBody}
    }`;

    return modifiedFunction;
  });
}
const getAllUserDefinedFunctionsAsAString = (codeString) => {
  const functionsDetected = extractJsFunctions(codeString);

  functionsDetected.forEach((funObj) => {
    if (codeString.includes(funObj.code + ";")) {
      codeString = codeString.replace(funObj.code + ";", "");
    } else {
      codeString = codeString.replace(funObj.code, "");
    }
  });

  let allFunctionDefinitionsString = "";
  functionsDetected.forEach((funObj) => {
    allFunctionDefinitionsString =
      allFunctionDefinitionsString + `\n` + funObj.code;
  });
  return allFunctionDefinitionsString;
};
export const bundleFunctions = (codeString) => {
  try {
    const allFunctionDefinitionsString =
      getAllUserDefinedFunctionsAsAString(codeString);
    // add allFunctionDefinitionsString in all function definitions whose name starts with on
    const modified = modifyOnlyOnFunctions(
      codeString,
      allFunctionDefinitionsString
    );

    return modified;
  } catch (error) {
    console.log("error", error);
  }
};
