import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import SignInButton from "../SignInButton";
import {
  useIsLoggedIn,
  useIsUnLoggedInCodeEditing,
  useShowLogInPopup,
  useUserInfo,
} from "../Zustand";
import { getEmailFromGoogleCredentials } from "../../network/credential";
import { loginToNanoWeb, storeUserObj } from "../../network/Network";
export default function LoginModal({
  updateLoginStateAndRef,

  onLogOut,
}) {
  const userInfo = useUserInfo((state) => state.userInfo);
  const setUserInfo = useUserInfo((state) => state.setUserInfo);

  const showLoginPopup = useShowLogInPopup((state) => state.showLoginPopup);
  const setIsLoggedIn = useIsLoggedIn((state) => state.setIsLoggedIn);
  const setIsUnLoggedInCodeEditing = useIsUnLoggedInCodeEditing(
    (state) => state.setIsUnLoggedInCodeEditing
  );
  const isUnLoggedInCodeEditing = useIsUnLoggedInCodeEditing(
    (state) => state.isUnLoggedInCodeEditing
  );

  const setShowLoginPopup = useShowLogInPopup(
    (state) => state.setShowLoginPopup
  );

  const onModalClose = () => {
    setShowLoginPopup(false);
  };
  const responseMessage = (response) => {
    const userObj = getEmailFromGoogleCredentials({
      credential: response.credential,
    });
    setUserInfo(userObj);
    storeUserObj({ value: JSON.stringify(userObj) });

    loginToNanoWeb({ email: userObj["email"] })
      .then((re) => {
        setIsLoggedIn(true);

        updateLoginStateAndRef();
        setShowLoginPopup(false);
        if (isUnLoggedInCodeEditing) {

        }
      })
      .catch(() => {});
  };
  return (
    <>
      <Transition appear show={showLoginPopup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onModalClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Sign In
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Kindly sign in to continue
                    </p>
                  </div>

                  <div className="mt-4">
                    {/* <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={onModalClose}
                    >
                      Login
                    </button> */}
                    <SignInButton
                      responseMessage={responseMessage}
                      userInfo={userInfo}
                      onLogOut={onLogOut}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
