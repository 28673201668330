import { useEffect, useReducer, useRef, useState } from "react";
import {
  checkAlphaNumDash,
  getIdentifierFromUrl,
  getJsonCodeFromCode,
  replaceUrlByProjectIdentifier,
  stringifyScreensArrayandSendToIframe,
} from "./utils/Utitlities";

import {
  getUpToDateAccessToken,
  getUserObjFromStorage,
  saveScreenCode,
} from "./network/Network";
import NanoEditor from "./components/NanoEditor";
import IframeLoader from "./components/IframeLoader";
import ListAppModal from "./components/modals/ListAppsModal";
import Header from "./components/Header";
import LoginModal from "./components/modals/LogInModal";
import {
  useAppIdentifer,
  useClickScreenOptionsInfo,
  useCodeInfo,
  useCodeSaveStatus,
  useIsLoggedIn,
  useRunTotalAppInfo,
  useScreensList,
  useSelectedScreenId,
  useSetProjectName,
  useShowIframe,
  useShowLogInPopup,
  useShowMediaModal,
  useUserInfo,
} from "./components/Zustand";
import { CODE_STORE } from "./utils/Constants";
import Instructions from "./components/modals/ApiInstructions";
import "prismjs/themes/prism-okaidia.css";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-jsx";
import "prismjs/components/prism-uri";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard";

import PublicAppModal from "./components/modals/PublicApp";
import { FloatingButton } from "./components/FloatingButton";
import DeleteAppModal from "./components/modals/DeleteApp";
import DeleteScreenModal from "./components/modals/DeleteScreen";
import { FloatingScreenCodeButton } from "./components/floatingbuttons/ScreenCodeButton";
import RunAppModal from "./components/modals/RunAppModal";
import ScreenOptionsModal from "./components/modals/ScreenOptions";
import SetAsFirstScreenModal from "./components/modals/SetAsFirstScreen";
import LogoutModal from "./components/modals/LogoutModal";
import MediaModal from "./components/modals/MediaModal";
import Drawer from "./components/Drawer/Drawer";

function App() {
  const codeInfo = useCodeInfo((state) => state.codeInfo);
  const setCodeInfo = useCodeInfo((state) => state.setCodeInfo);

  const setUserInfo = useUserInfo((state) => state.setUserInfo);

  const [isListAppsModalOpen, setIsListAppsModalOpen] = useState(false);
  const currentCodeRef = useRef(null);

  const currentJsonCodeRef = useRef(null);
  const [isSaving, setIsSaving] = useState("");

  const projectName = useSetProjectName((state) => state.projectName);
  const setProjectName = useSetProjectName((state) => state.setProjectName);

  const setShowLoginPopup = useShowLogInPopup(
    (state) => state.setShowLoginPopup
  );
  const isLoggedIn = useIsLoggedIn((state) => state.isLoggedIn);
  const setIsLoggedIn = useIsLoggedIn((state) => state.setIsLoggedIn);
  const isLoggedInStateRef = useRef(false);
  const [isLoggedInState, setIsLoggedInState] = useState(
    isLoggedInStateRef.current
  );

  const screensListRef = useRef([]);
  const setScreensList = useScreensList((state) => state.setScreensList);

  const [showInstructionPopup, setShowInstructionPopup] = useState(false);
  const [showPublicAppModal, setShowPublicAppModal] = useState(false);
  const [showDeleteAppModal, setShowDeleteAppModal] = useState(false);
  const [showDeleteScreenModal, setShowDeleteScreenModal] = useState(false);
  const [screenIdToBeDeleted, setScreenIdToBeDeleted] = useState(null);
  const [appInfoTobeDeleted, setAppInfoTobeDeleted] = useState(null);

  const isNewAppCreatingRef = useRef(false);
  const [isNewAppCreating, setIsNewAppCreating] = useState(
    isNewAppCreatingRef.current
  );
  const setClickedScreenOptionsInfo = useClickScreenOptionsInfo(
    (state) => state.setClickedScreenOptionsInfo
  );

  const pageNumRef = useRef(1);
  const screenSearchStringRef = useRef("");

  const canFetchNextPageRef = useRef(false);
  const appIdentifier = useAppIdentifer((state) => state.appIdentifier);
  const setAppIdentifer = useAppIdentifer((state) => state.setAppIdentifer);

  const setShowIframe = useShowIframe((state) => state.setShowIframe);
  const setRunTotalAppInfo = useRunTotalAppInfo(
    (state) => state.setRunTotalAppInfo
  );
  const setShowMediaModel = useShowMediaModal(
    (state) => state.setShowMediaModel
  );
  const setCodeSaveStatus = useCodeSaveStatus(
    (state) => state.setCodeSaveStatus
  );

  const setSelectedScreenId = useSelectedScreenId(
    (state) => state.setSelectedScreenId
  );
  const selectedScreenId = useSelectedScreenId(
    (state) => state.selectedScreenId
  );
  const isApiClickedRef = useRef(false);
  const editorRef = useRef(null);

  let autoSaveTimeOut = null;

  const getScreenList = (searchString = "", isScroll = false) => {};
  const updateAllAboveStatesFromCodeResponse = (r) => {
    if (r) {
      setCodeInfo(r);
      const code_json = getJsonCodeFromCode(r.code);
      stringifyScreensArrayandSendToIframe(JSON.parse(code_json));
      currentCodeRef.current = r.code;
      currentJsonCodeRef.current = code_json;
      setProjectName(r.name);
      if (isLoggedIn) {
        if (r != null && r.status === CODE_STORE.PUBLISH) {
          setIsSaving("Published");
        } else {
          setIsSaving("Draft saved");
        }
      }

      if (r.identifier != null) {
        replaceUrlByProjectIdentifier(r.identifier);
        setAppIdentifer(r.identifier);
      }
     
      if (isApiClickedRef.current) {
        setShowInstructionPopup(true);
        isApiClickedRef.current = false;
      }
    }
  };

  const updateLoginStateAndRef = (response) => {
    isLoggedInStateRef.current = true;
    setIsLoggedInState(isLoggedInStateRef.current);
  };
  const checkAccessTokenAndUpdateIsLoggedIn = async () => {
    const acc = await getUpToDateAccessToken();
    if (acc != null) {
      setIsLoggedIn(true);
      isLoggedInStateRef.current = true;
    } else {
      setIsLoggedIn(false);
      isLoggedInStateRef.current = false;
    }
    setIsLoggedInState(isLoggedInStateRef.current);
  };

  useEffect(() => {
    checkAccessTokenAndUpdateIsLoggedIn();

    const urlIdentifier = getIdentifierFromUrl();
    if (urlIdentifier && checkAlphaNumDash(urlIdentifier + "")) {
      setAppIdentifer(urlIdentifier);
    } else {
      setAppIdentifer(null);
    }
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      const storedUserObj = getUserObjFromStorage();

      setUserInfo(JSON.parse(storedUserObj));
    }
  }, [isLoggedIn]);

  const onCloseListAppsModal = () => {
    setIsListAppsModalOpen(false);
  };
  const onAppSelect = (selectedAppObj) => {
    if (selectedAppObj && selectedAppObj.identifier) {
      pageNumRef.current = 1;
      canFetchNextPageRef.current = false;
      setScreensList([]);
      setSelectedScreenId(null);
      setAppIdentifer(selectedAppObj.identifier);

      onCloseListAppsModal();

      window.history.replaceState(
        "nan",
        "ss",
        window.location.origin + "/" + selectedAppObj.identifier + "/"
      );
    }
  };
  useEffect(() => {
    if (appIdentifier) {
    }
  }, [appIdentifier]);
  const saveCode = (totalObj, isCoding = false) => {
    if (isLoggedIn) {
      if (totalObj != null && totalObj.status === CODE_STORE.PUBLISH) {
        setCodeSaveStatus("Publishing...");
      } else {
        setCodeSaveStatus("Draft saving...");
      }
    }

    if (totalObj != null && totalObj.identifier == null) {
      delete totalObj["identifier"];
    }
    if (totalObj != null && totalObj.json_code != null) {
      const mCode = JSON.parse(totalObj.json_code);
      const updatedTotalObj = {
        ...totalObj,
        json_code: JSON.stringify(mCode[0]),
      };

      saveScreenCode(updatedTotalObj)
        .then((re) => {
          if (re) {
            if (re.status === CODE_STORE.PUBLISH) {
              setCodeSaveStatus("Published");
              if (totalObj != null && totalObj.status === CODE_STORE.PUBLISH) {
                setShowInstructionPopup(true);
              }
            } else {
              setCodeSaveStatus("Draft saved");
            }
          }

          if (isCoding) {
            if (re) {
              if (isLoggedIn) {
              }

              replaceUrlByProjectIdentifier(re.identifier);
            }
          } else {
            updateAllAboveStatesFromCodeResponse(re);
          }
        })
        .catch(() => {});
    }
  };
  const updateCodeRefAndCodeJSONRef = ({ currentCode, currentCodeJson }) => {
    currentCodeRef.current = currentCode;
    currentJsonCodeRef.current = currentCodeJson;
  };

  const onCodeChange = ({ currentCode, currentCodeJson }) => {
    currentCodeRef.current = currentCode;
    currentJsonCodeRef.current = currentCodeJson;
    if (isLoggedIn) {
      if (autoSaveTimeOut) {
        clearTimeout(autoSaveTimeOut);
      }
      autoSaveTimeOut = setTimeout(() => {
        saveCode(
          {
            ...codeInfo,
            json_code: currentCodeJson,
            code: currentCode,
            name: projectName,
            status: CODE_STORE.DRAFT,
          },
          true
        );
      }, 4500);
    }
  };

  const onLogOut = () => {};

  const onPublishPress = (val) => {
    if (isLoggedIn) {
      saveCode({
        ...codeInfo,
        json_code: currentJsonCodeRef.current,
        code: currentCodeRef.current,
        name: projectName,
        status:
          val === CODE_STORE.PUBLISH ? CODE_STORE.PUBLISH : CODE_STORE.DRAFT,
      });
    } else {
      setShowLoginPopup(true);
    }
  };
  const onMyAppsPress = () => {
    if (isLoggedIn) {
      setIsListAppsModalOpen(true);
    } else {
      setShowLoginPopup(true);
    }
  };

  const onAPIPress = () => {
    if (isLoggedIn) {
      isApiClickedRef.current = true;
      saveCode({
        ...codeInfo,
        json_code: currentJsonCodeRef.current,
        code: currentCodeRef.current,
        name: projectName,
        status:
          codeInfo != null && codeInfo.status === CODE_STORE.PUBLISH
            ? CODE_STORE.PUBLISH
            : CODE_STORE.DRAFT,
      });
    } else {
      setShowLoginPopup(true);
    }
  };
  const onCloseInstructionModal = () => {
    setShowInstructionPopup(false);
  };
  const onSharePress = () => {
    if (isLoggedIn) {
      setShowPublicAppModal(true);
    } else {
      setShowLoginPopup(true);
    }
  };
  const closePublicAppModal = () => {
    setShowPublicAppModal(false);
  };
  const closeDeleteAppModal = () => {
    setShowDeleteAppModal(false);
  };

  const onRunPress = () => {
    if (window.innerWidth < 720) {
      setShowIframe(true);
    }
    const iframe = document.getElementById("screens_iframe");
    const temp = iframe.src;
    iframe.contentWindow.location.href = temp;
    iframe.src = temp;
  };

  const onIframeLoad = () => {
    if (editorRef.current != null) {
      const code = editorRef.current.getValue();
      if (code != null && code.length > 0) {
        const code_json = getJsonCodeFromCode(code);
        stringifyScreensArrayandSendToIframe(JSON.parse(code_json));
      }
    }
  };

  const onDeleteAppSelect = (seletedApp) => {
    setAppInfoTobeDeleted(seletedApp);
    setIsListAppsModalOpen(false);
    setShowDeleteAppModal(true);
  };

  const closeDeleteScreenModal = () => {

    setScreenIdToBeDeleted(null);
    setClickedScreenOptionsInfo(null);
    setShowDeleteScreenModal(false);

  };

  const onDeleteScreenClick = (deleteScreenId) => {
    setScreenIdToBeDeleted(deleteScreenId);
    setShowDeleteScreenModal(true);
  };
  const setCodeInRunTotalAppInfo = () => {
    setRunTotalAppInfo(currentJsonCodeRef.current);
  };

  const onDesignClick = () => {};
  const onAssetsClick = () => {
    if (isLoggedIn) {
      setShowMediaModel(true);
    } else {
      setShowLoginPopup(true);
    }
  };
  const onPluginsPress = () => {
    if (isLoggedIn) {
    } else {
      setShowLoginPopup(true);
    }
  };
  const saveCodeWithUpdatedProjectName = (newProjectName) => {
    if (isLoggedIn) {
      saveCode({
        ...codeInfo,
        json_code: currentJsonCodeRef.current,
        code: currentCodeRef.current,
        name: newProjectName,
        status:
          codeInfo != null && codeInfo.status === CODE_STORE.PUBLISH
            ? CODE_STORE.PUBLISH
            : CODE_STORE.DRAFT,
      });
    } else {
      setShowLoginPopup(true);
    }
  };
  return (
    <div className="h-[100vh] overflow-y-clip ">
      <Header
        onPublishPress={onPublishPress}
        onMyAppsPress={onMyAppsPress}
        onSharePress={onSharePress}
        onRunPress={onRunPress}
        setCodeInRunTotalAppInfo={setCodeInRunTotalAppInfo}
        saveCodeWithUpdatedProjectName={saveCodeWithUpdatedProjectName}
      />

      <div className="flex h-[calc(100vh-60px)]">
        <Drawer
          onAssetsClick={onAssetsClick}
          onDesignClick={onDesignClick}
          onAPIPress={onAPIPress}
          onPluginsPress={onPluginsPress}
        />


        <NanoEditor
          onCodeChange={onCodeChange}
          updateCodeRefAndCodeJSONRef={updateCodeRefAndCodeJSONRef}
          editorRef={editorRef}
          onDeleteScreenClick={onDeleteScreenClick}
        />

        <IframeLoader onIframeLoad={onIframeLoad} />
        <FloatingButton />
        <FloatingScreenCodeButton />
      </div>
      {/* ) : (
        <div
          style={{
            width: "100%",
            height: "91vh",
          }}
          className=" items-center justify-center flex  bg-gray-800 "
        >
          <button
            onClick={onProfileIconPress}
            className=" relative flex rounded-lg hover:bg-green-800 bg-green-600 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            <p
              className={
                "text-gray-100 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
              }
              aria-current={"page"}
            >
              {"Login to view this app"}
            </p>
          </button>
        </div>
      )} */}

      <ListAppModal
        isModalOpen={isListAppsModalOpen}
        closeModal={onCloseListAppsModal}
        onAppClick={onAppSelect}
        onDeleteAppSelect={onDeleteAppSelect}
      />
      <LoginModal
        onLogOut={onLogOut}
        updateLoginStateAndRef={updateLoginStateAndRef}
      />
      <Instructions
        isInstructionsModalOpen={showInstructionPopup}
        closeInstructionsModal={onCloseInstructionModal}
      />
      <PublicAppModal
        showPublicAppModal={showPublicAppModal}
        closePublicAppModal={closePublicAppModal}
        identifier={codeInfo?.identifier}
      />

      <DeleteAppModal
        showDeleteAppModal={showDeleteAppModal}
        closeDeleteAppModal={closeDeleteAppModal}
        appInfoTobeDeleted={appInfoTobeDeleted}
      />

      <DeleteScreenModal
        closeDeleteScreenModal={closeDeleteScreenModal}
        screenIdToBeDeleted={screenIdToBeDeleted}
        showDeleteScreenModal={showDeleteScreenModal}
      />
      <RunAppModal />
      <ScreenOptionsModal
        screenIdToBeDeleted={screenIdToBeDeleted}
        onScreenDeleteClick={onDeleteScreenClick}
      />
      <LogoutModal />
      <SetAsFirstScreenModal getScreenList={getScreenList} />
      <MediaModal appName={codeInfo?.name} identifier={codeInfo?.identifier} />
    </div>
  );
}

export default App;
