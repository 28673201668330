import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import {
  useClickScreenOptionsInfo,
  useScreenOptionsShow,
  useShowSetAsFirstScreenModal,
} from "../Zustand";

const RenderButton = ({ title, subtitle, onClick }) => {
  return (
    <div onClick={onClick} className="bg-gray-50 p-4 cursor-pointer ">
      {/* <a
        href="##"
        className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
      > */}
      <span className="flex items-center">
        <span className="text-sm font-medium text-gray-900">{title}</span>
      </span>
      <span className="block text-sm text-gray-500">{subtitle}</span>
      {/* </a> */}
    </div>
  );
};
function ScreenOptionsModal({ screenIdToBeDeleted, onScreenDeleteClick }) {
  const [loading, setLoading] = useState(false);
  const showScreenOptions = useScreenOptionsShow(
    (state) => state.showScreenOptions
  );
  const setShowScreenOptions = useScreenOptionsShow(
    (state) => state.setShowScreenOptions
  );

  const clickedScreenOptionsInfo = useClickScreenOptionsInfo(
    (state) => state.clickedScreenOptionsInfo
  );

  const setShowSetAsFirstScreenModal = useShowSetAsFirstScreenModal(
    (state) => state.setShowSetAsFirstScreenModal
  );

  const onDeletePress = () => {
    onScreenDeleteClick(clickedScreenOptionsInfo);
    setShowScreenOptions(false);
  };

  const closeScreenOptionsModal = () => {
    setShowScreenOptions(false);
  };
  const onSetAsFirstScreenClick = () => {
    // onScreenDeleteClick(clickedScreenOptionsInfo);
    closeScreenOptionsModal();
    setShowSetAsFirstScreenModal(true);
  };

  return (
    <Transition appear show={showScreenOptions} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={closeScreenOptionsModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Actions
                </Dialog.Title>
                <div className="mt-2 items-center ">
                  <div className="mt-4  flex flex-col ">
                    <RenderButton
                      title={" Set as first"}
                      subtitle={
                        "This screen will be loaded first when you run the app"
                      }
                      onClick={onSetAsFirstScreenClick}
                    />
                    <RenderButton
                      title={"Delete"}
                      subtitle={"Once deleted, you cannot get the screen back"}
                      onClick={onDeletePress}
                    />

                    {/* <button
                      type="button"
                      className={classNames(
                        "mb-2",
                        " inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      )}
                      onClick={onSetAsFirstScreenClick}
                      disabled={loading}
                    >
                      {"Set screen as first screen"}
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        "",
                        "inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      )}
                      onClick={onDeletePress}
                      disabled={loading}
                    >
                      {loading && <LoadingIndicator />} {"Delete Screen"}
                    </button> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ScreenOptionsModal;
