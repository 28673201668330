import { useEffect } from "react";
import Prism from "prismjs";

const CodeBlock = ({ children, language, preClass, codeClass }) => {
  useEffect(() => {
    const time = setTimeout(() => {
      Prism.highlightAll();
    }, 200);
    return () => {
      if (time) {
        clearTimeout(time);
      }
    };
  }, []);
  const commonClasses = " ";
  return (
    <pre
      className={`${language}  ${preClass}   whitespace-pre-wrap     `}
    >
      <code
        className={` ${language}  whitespace-pre-wrap !text-sm  ${codeClass}  `}
      >
        {children}
      </code>
    </pre>
  );
};
export default CodeBlock;
