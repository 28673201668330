import { useState } from "react";
import { BiPencil } from "react-icons/bi";

const InputField = ({
  value,
  onChange,
  inputClasses = "",
  placeholder,
  isEdit = false,
  onProjectNameInputFieldBlur,
}) => {
  const [showInput, setShowInput] = useState(isEdit);
  return (
    <div className=" flex flex-row items-center w-48 ">
      {showInput ? (
        <input
          onBlur={() => {
            setShowInput(false);
            onProjectNameInputFieldBlur(value);
          }}
          autoFocus={true}
          type="text"
          // name="first-name"
          // id="first-name"
          autoComplete="given-name"
          value={value}
          onChange={onChange}
          // defaultValue={value}
          placeholder={placeholder ? placeholder : "Project name"}
          className={`block w-full outline-none pl-2 rounded-sm border-0 py-1.5 text-gray-900   placeholder:text-gray-400  sm:text-sm sm:leading-6 ${inputClasses} `}
        />
      ) : (
        <div className="flex flex-row pr-2 pl-2 w-full rounded-sm border-gray-700 py-1 border-[1px] justify-between items-center   ">
          <p className="text-white  text-sm  sm:leading-6 "> {value} </p>
          <BiPencil
            onClick={() => {
              setShowInput(true);
            }}
            size={18}
            color="white"
            className="cursor-pointer"
          />
        </div>
      )}
    </div>
  );
};

export default InputField;
