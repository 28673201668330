import { loadingScreenStringCOde } from "./Constants";
import { bundleFunctions } from "./CustomBundler";

let timeout = null;
export function removeDangerousCode(code) {
  code = code.replace(/\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm, "");
  code = code.replace(/^return.*$/gm, "");
  code = code.replace(/eval/gi, "");
  // code = code.replace(/Function/gi, "");
  // code = code.replace(/function\s*\w*\s*\([^\)]*\)\s*{[\s\S]*?}/gi, "");
  return code;
}

export function addReturnCode(code) {
  code =
    code +
    "\n" +
    "const screens = [screen]; " + // added this line, so this function works with single screen
    "return JSON.stringify(screens, function (key, value) {" +
    "if (typeof value === 'function') {" +
    "return value.toString();" +
    "} else {" +
    "return value;" +
    "}" +
    "}, 4);";
  return code;
}
export function getJsonCodeFromCode(value) {
  const sterilisedCode = removeDangerousCode(value);
  const bundledCode = bundleFunctions(sterilisedCode);
  const js_code = addReturnCode(bundledCode);

  try {
    const temmp = Function(js_code)();

    return temmp;
  } catch (error) {
    console.log("json error", error);

    return null;
  }
}

export const formatDocument = (editorInstance) => {
  if (
    editorInstance != null &&
    editorInstance.getAction != null &&
    typeof editorInstance.getAction == "function"
  ) {
    editorInstance
      .getAction("editor.action.formatDocument")
      .run()
      .then(() => {
        // editorInstance.updateOptions({ readOnly: true })
      })
      .catch(() => {});
  }
};
export function checkAlphaNumDash(string) {
  const regex = /^[a-zA-Z0-9-]*$/;

  const match = regex.test(string);
  return match;
}

export const stringifyScreensArrayandSendToIframe = (screensArr) => {
  const iframe = document.getElementById("screens_iframe");
  if (iframe) {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      // const loadingScreenStringCOde =  JSON.stringify(JSON.parse(getJsonCodeFromCode(loadingScreenCode)))
      if (iframe != null && iframe.contentWindow != null) {
        iframe.contentWindow.postMessage(
          JSON.stringify(screensArr),
          "https://www.nanoapp.dev/dashboard/preview"
        );
      }
    }, 10);
  }
};

export const getIdentifierFromUrl = () => {
  const currentUrl = window.location.href;
  const urlAfterEndSlashRemoved = currentUrl.slice(0, -1);
  const lastSlashIndexNum = urlAfterEndSlashRemoved.lastIndexOf(
    "/",
    urlAfterEndSlashRemoved.length - 2
  );
  const urlIdentifier = urlAfterEndSlashRemoved.slice(
    lastSlashIndexNum + 1,
    urlAfterEndSlashRemoved.length
  );
  return urlIdentifier;
};

export const replaceUrlByProjectIdentifier = (identifier) => {
  const currWindowUrl = window.location.href;

  if (identifier != null) {
    if (!currWindowUrl.includes(identifier)) {
      window.history.replaceState(
        "nan",
        "ss",
        window.location.origin + "/" + identifier + "/"
      );
    }
  } else {
    window.history.replaceState("nan", "ss", window.location.origin + "/");
  }
};
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function isJsonCodeContainsAllScreenData(allScreensJsonCode) {
  // console.log("typeeee", typeof allScreensJsonCode);

  if (
    allScreensJsonCode != null &&
    typeof allScreensJsonCode == "object" &&
    allScreensJsonCode.length > 0
  ) {
    const badScreenObj = allScreensJsonCode.find((singleScreenObj) => {
      if (
        singleScreenObj == null ||
        typeof singleScreenObj != "object" ||
        Object.keys(singleScreenObj) < 1
      ) {
        return true;
      }
    });
    if (badScreenObj) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
