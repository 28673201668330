import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { BiCopy } from "react-icons/bi";
import { classNames } from "../../utils/Utitlities";
import MonacoCodeBlock from "../MonacoCodeBlock";
import {
  useClickAssetDetails,
  useShouldShowAssetsDetailsModal,
} from "../Zustand";
import SvgInline from "../itemviews/IconSvgItemView";
import FontsItemView from "../itemviews/FontsItemView";

function AssetDetailsModal() {
  const shouldShowAssetsDetailModal = useShouldShowAssetsDetailsModal(
    (state) => state.shouldShowAssetsDetailModal
  );
  const setShouldShowAssetsDetailModal = useShouldShowAssetsDetailsModal(
    (state) => state.setShouldShowAssetsDetailModal
  );

  const clickedAssetDetails = useClickAssetDetails(
    (state) => state.clickedAssetDetails
  );

  const closeAssetDetailsModal = () => {
    setShouldShowAssetsDetailModal(false);
  };
  const checkAndReturnFileTypes = () => {
    if (clickedAssetDetails?.type?.includes("svg")) {
      return "svg";
    }
    if (clickedAssetDetails?.type?.includes("font")) {
      return "font";
    }
    if (clickedAssetDetails?.type?.includes("image")) {
      return "Image";
    }
  };

  const getDisplayBasedOnType = () => {
    const simpleType = checkAndReturnFileTypes();

    switch (simpleType) {
      case "Image":
        return (
          <img
            alt=""
            className=" max-h-[64vh] resize-y    "
            // className=" h-48 w-48  border-red-500 border-2 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
            src={clickedAssetDetails?.file}
          />
        );
      case "svg":
        return (
          <SvgInline
            url={clickedAssetDetails.file}
            height={150}
            width={200}
            // onDeleteClick={onDeleteAssetClick}
            item={clickedAssetDetails}
            // onAssetClick={onAssetClick}
            showBorder={false}
          />
        );
      case "font":
        return (
          <FontsItemView
            // onDeleteClick={onDeleteAssetClick}
            item={clickedAssetDetails}
            // onAssetClick={onAssetClick}
          />
        );
      default:
        return null;
    }
  };
  const removeExtension = (fileName) => {
    // remove extension from filename and return the rest
    return fileName.slice(0, fileName.indexOf("."));
  };
  const getDisplayCodeBasedOnFileType = () => {
    const simpleType = checkAndReturnFileTypes();

    switch (simpleType) {
      case "Image":
        return `const image = {
            name: "${removeExtension(clickedAssetDetails?.name)}",
            component: 'image',
            value: "${clickedAssetDetails?.file}",
            props: {
              style: {
                width: 220,
                height: 50,
              },
            },
          };
          `;
      case "svg":
        return `const svg = {
          name: "${removeExtension(clickedAssetDetails?.name)}",
          component: 'image',
          value: "${clickedAssetDetails?.file}",
          props: {
            style: {
              width: 220,
              height: 50,
            },
          },
        };
        `;

      case "font":
        return `const text = {
          name: "${removeExtension(clickedAssetDetails?.name)}",
          component: 'text',
          value: "Hi Nano",
          props: {
            style: {
              width: 220,
              height: 50,
              fontFamily:"${removeExtension(clickedAssetDetails?.name)}"
            },
          },
        };
        `;
      default:
        return null;
    }
  };
  return (
    <Transition appear show={shouldShowAssetsDetailModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={closeAssetDetailsModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed  inset-0 ">
          <div className="flex min-h-full items-center justify-center p-4 text-center  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full   max-w-3xl transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-base justify-center flex font-medium leading-6 text-gray-900"
                >
                  {clickedAssetDetails?.name}
                </Dialog.Title>
                <div className=" flex pr-2 pl-2 rounded-md border-gray-300 border-[0.5px] items-center mt-2 ">
                 
                  <div className="flex justify-center items-center  w-[35%] max-h-[64vh] ">
                    {getDisplayBasedOnType()}
                  </div>

                  <div className="mt-2 pt-5 pb-5 pl-5 pr-5  w-[65%] overflow-y-scroll">
                    <Dialog.Title
                      as="h3"
                      className="text-sm font-bold  flex  leading-6 text-gray-900"
                    >
                      {"Link "}{" "}
                    </Dialog.Title>
                    <div className="flex ">
                      <div className="w-[90%] overflow-y-hidden overflow-x-hidden    ">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={clickedAssetDetails?.file}
                          className=" line-clamp-1 whitespace-nowrap  cursor-pointer text-blue-400 underline "
                        >
                          {clickedAssetDetails?.file}{" "}
                        </a>
                      </div>
                      <BiCopy
                        onClick={() => {
                          navigator.clipboard.writeText(
                            clickedAssetDetails?.file
                          );
                        }}
                        className="ml-3 cursor-pointer  transition-all  focus:opacity-[0.85] focus:shadow-none active:opacity-[0.15] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        size={25}
                        color="gray"
                      />
                    </div>

                    <br />
                    <Dialog.Title
                      as="h3"
                      className="text-sm font-bold  leading-6 text-gray-900"
                    >
                      Code 
                    </Dialog.Title>

                    <MonacoCodeBlock code={getDisplayCodeBasedOnFileType()} />
                  </div>
                </div>
                <div className="mt-4 flex justify-end ">
                  <button
                    type="button"
                    className={classNames(
                      "",
                      // isPublic
                      //   ? "bg-red-600 text-white"
                      //   : "bg-green-600 text-gray-300 hover:bg-green-700 hover:text-white",
                      " inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    )}
                    onClick={closeAssetDetailsModal}
                    // disabled={loading}
                  >
                    {"Close"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AssetDetailsModal;
