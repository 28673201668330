import { useEffect } from "react";
import { getUserObjFromStorage } from "../network/Network";
import { classNames, getIdentifierFromUrl } from "../utils/Utitlities";
import { useShowIframe } from "./Zustand";

const IframeLoader = ({ onIframeLoad }) => {
  /// code editor

  const showIframe = useShowIframe((state) => state.showIframe);

  const urlIdentifier = getIdentifierFromUrl();
  useEffect(() => {
    // disabling right click on the iframe
    // window.frames["screens_iframe"].contentDocument.oncontextmenu =
    //   function () {
    //     return false;
    //   };
  }, []);

  return (
    <div
      // style={{ height: (window.innerHeight * 91) / 100 }}
      className={classNames(
        showIframe ? "max-sm:min-w-full" : " max-sm:hidden",
        "w-[25%] h-[calc(100vh-60px)]"
      )}
    >
      <iframe
        title="Nano-iframe"
        height={"100%"}
        width={"100%"}
        id="screens_iframe"
        src={`https://www.nanoapp.dev/dashboard/preview/?app_id=${urlIdentifier}`}
        onLoad={onIframeLoad}
      ></iframe>
    </div>
  );
};

export default IframeLoader;
