import jwtDecode from "jwt-decode";
import {
  storeAccessToken,
  storeIsLoggedIn,
  storeRefreshToken,
} from "./Network";
// import {EventRegister} from 'react-native-event-listeners';

async function getAccessUsingRefresh(refreshToken, url) {
  return fetch(url + refreshToken, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refresh: refreshToken }),
  }).then((res) => res.json());
}

export async function getVerifiedAccessToken(access_token, refresh_token, url) {
  // console.log("get verified access token function");

  if (access_token != null && refresh_token != null) {
    // console.log("botha are not null", refresh_token, access_token);

    if (!isTokenExpired(access_token)) {
      // console.log("access token not expired");

      return "jwt " + access_token;
    } else {
      // console.log("elssse refresh", refresh_token);

      if (!isTokenExpired(refresh_token)) {
        // console.log("refresh token not expired");

        const response = await getAccessUsingRefresh(refresh_token, url);

        storeAccessToken({ access_token: response.access });
        storeRefreshToken({ refresh_token: response.refresh });

        return "jwt " + response.access;
      } else {
        //invalid-tokens
        // EventRegister.emit('invalid-tokens', true);
        console.log("both token expired");
        // storeAccessToken({ access_token: '' });
        // storeRefreshToken({ refresh_token: '' });
        // storeIsLoggedIn("0");
        // localStorage.clear();

        return null;
      }
    }
  } else {
    // storeIsLoggedIn("0");
    // localStorage.clear();

    return null;
  }
}

export function isTokenExpired(token) {
  var decoded = jwtDecode(token);
  // console.log("decoded", decoded);

  if (decoded.exp < Date.now() / 1000) {
    return true;
  } else {
    return false;
  }
}

export const getEmailFromGoogleCredentials = ({ credential }) => {
  const USER_CREDENTIAL = jwtDecode(credential);
  // console.log(USER_CREDENTIAL);
  return USER_CREDENTIAL;
};
