import { Dialog, Transition } from "@headlessui/react";
import { googleLogout } from "@react-oauth/google";
import React, { Fragment, useState } from "react";
import { classNames } from "../../utils/Utitlities";
import { LoadingIndicator } from "../LoadingIndicator";
import { useIsLoggedIn, useShowLogoutModal } from "../Zustand";

function LogoutModal() {
  const [loading, setLoading] = useState(false);
  const showLogoutModal = useShowLogoutModal((state) => state.showLogoutModal);

  const setShowLogoutModal = useShowLogoutModal(
    (state) => state.setShowLogoutModal
  );
  const setIsLoggedIn = useIsLoggedIn((state) => state.setIsLoggedIn);

  const onLogoutPress = () => {
    setLoading(true);
    setIsLoggedIn(false);
    localStorage.clear();
    googleLogout();
    setLoading(false);

    setShowLogoutModal(false);
  };
  const onCancelButton = () => {
    setShowLogoutModal(false);
  };

  return (
    <Transition appear show={showLogoutModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancelButton}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Logout
                </Dialog.Title>
                <div className="mt-2 items-center ">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to log out ?
                  </p>

                  <div className="mt-4 flex justify-end ">
                    <button
                      type="button"
                      className={classNames(
                        "",
                        // isPublic
                        //   ? "bg-red-600 text-white"
                        //   : "bg-green-600 text-gray-300 hover:bg-green-700 hover:text-white",
                        " inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      )}
                      onClick={onCancelButton}
                      disabled={loading}
                    >
                      {"Cancel"}
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        "",
                        // isPublic
                        //   ? "bg-red-600 text-white"
                        //   : "bg-green-600 text-gray-300 hover:bg-green-700 hover:text-white",
                        "ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      )}
                      onClick={onLogoutPress}
                      disabled={loading}
                    >
                      {loading && <LoadingIndicator />} {"Logout"}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default LogoutModal;
