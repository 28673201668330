import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useFilePicker } from "use-file-picker";
import { uploadAssets } from "../../network/Network";
import { getIdentifierFromUrl } from "../../utils/Utitlities";
import ImageCropper from "../ImageCropper";
import { useShouldShowAppLogoCropViewerModal } from "../Zustand";

const getFileExtensionBasedOnAssetType = (assetType) => {
  switch (assetType) {
    case "Media":
      return [".jpg, .jpeg, .png", ".svg"];
    case "App Logo":
      return [".jpg, .jpeg, .png", ".svg"];

    case "Icons":
      return [".ttf", ".svg"];

    case "Fonts":
      return [".ttf"];

    default:
      return [".jpg, .jpeg, .png", ".svg"];
  }
};

const AppLogoCropViewerModal = ({
  appName,
  item,
  onUploadLauncherIconPress,
}) => {
  const [uploadingFile, setUploadingFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadingFilesArray, setUploadingFilesArray] = useState([]);

  const [showCropper, setShowCropper] = useState(false);
  const assetTypeRef = useRef(null);
  const { openFilePicker, plainFiles, clear } = useFilePicker({
    accept: getFileExtensionBasedOnAssetType(assetTypeRef.current),
    readAs: "DataURL",
    onClear: () => {},
    multiple: true,
  });

  const shouldShowAppLogoCropViewerModal = useShouldShowAppLogoCropViewerModal(
    (state) => state.shouldShowAppLogoCropViewerModal
  );
  const setShouldShowAppLogoCropViewerModal =
    useShouldShowAppLogoCropViewerModal(
      (state) => state.setShouldShowAppLogoCropViewerModal
    );
  const onMediaModalClose = () => {
    setShouldShowAppLogoCropViewerModal(null);
  };

  const onUploadProgress = (uploadedPercentageValue) => {
    setUploadProgress(uploadedPercentageValue);
  };
  const uploadFiles = (asset_type, file) => {
    const urlIdentifier = getIdentifierFromUrl();
    setUploadingFile(file);

    uploadAssets({
      identifier: urlIdentifier,
      asset_type,
      file,
      onUploadProgress: onUploadProgress,
    })
      .then((d) => {
        setUploadingFile(null);
        setUploadProgress(null);
        const tempUploadFilesArray = [...uploadingFilesArray];
        const res = tempUploadFilesArray.shift();
        if (res != null) {
          setUploadingFilesArray(tempUploadFilesArray);
        }
        clearFiles();
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (uploadingFilesArray != null && uploadingFilesArray.length > 0)
      switch (assetTypeRef.current) {
        case "Media":
          uploadFiles("images", uploadingFilesArray[0]);

          break;
        case "App Logo":
          uploadFiles("launcher_icons", uploadingFilesArray[0]);

          break;
        case "Icons":
          uploadFiles("images", uploadingFilesArray[0]);

          break;
        case "Fonts":
          uploadFiles("fonts", uploadingFilesArray[0]);

          break;

        default:
          break;
      }
  }, [uploadingFilesArray]);
  useEffect(() => {
    if (plainFiles != null && plainFiles.length > 0) {
      setUploadingFilesArray(plainFiles);
    } else {
    }
  }, [plainFiles]);
  const clearFiles = () => {
    clear();
  };

  const onUploadAssetPress = (fil, croppedArea) => {
    onUploadLauncherIconPress(fil, croppedArea);
    onMediaModalClose();
  };

  useEffect(() => {
    setShowCropper(false);
    setTimeout(() => {
      setShowCropper(true);
    }, 3000);
  }, []);

  return (
    <Transition
      appear
      show={shouldShowAppLogoCropViewerModal != null}
      as={Fragment}
    >
      <Dialog
        static={true}
        as="div"
        className="relative z-10"
        onClose={onMediaModalClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed  inset-0 ">
          <div className="flex min-h-full items-center justify-center p-4 text-center  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-1/2  transform  rounded-2xl bg-white p-6 text-left  shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl mb-2 flex text-center font-medium leading-6 text-npurple"
                >
                  {appName}{" "}
                  {/* <p className="ml-2 text-lg leading-6 text-black "> assets</p> */}
                </Dialog.Title>
                <div className="    px-2 py-2 sm:px-0">
                  {showCropper && (
                    <ImageCropper
                      file={shouldShowAppLogoCropViewerModal}
                      onMediaModalClose={onMediaModalClose}
                      onUploadLauncherIconPress={onUploadAssetPress}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AppLogoCropViewerModal;
