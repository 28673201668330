import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { deleteAsset } from "../../network/Network";
import { classNames } from "../../utils/Utitlities";
import { LoadingIndicator } from "../LoadingIndicator";
import { useShouldReloadAssetsData, useShowDeleteAssetModal } from "../Zustand";

const DeleteAssetModal = ({ refresh }) => {
  const [loading, setLoading] = useState(false);
  const showDeleteAssetModal = useShowDeleteAssetModal(
    (state) => state.showDeleteAssetModal
  );

  const setShowDeleteAssetModel = useShowDeleteAssetModal(
    (state) => state.setShowDeleteAssetModel
  );
  const shouldReloadAssetsData = useShouldReloadAssetsData(
    (state) => state.shouldReloadAssetsData
  );
  const setShouldReloadAssetsData = useShouldReloadAssetsData(
    (state) => state.setShouldReloadAssetsData
  );
  const onDeleteAssetCloseModal = () => {
    setShowDeleteAssetModel(null);
  };
  const onDeletePress = () => {
    if (showDeleteAssetModal != null) {
      setLoading(true);
      deleteAsset({ asset_id: showDeleteAssetModal?.id })
        .then((res) => {
          setLoading(false);
          refresh();

          setShouldReloadAssetsData(true);

          onDeleteAssetCloseModal();
        })
        .catch(() => {});
    }
  };

  return (
    <Transition appear show={showDeleteAssetModal != null} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={onDeleteAssetCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Delete
                </Dialog.Title>
                <div className="mt-2 items-center ">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete this asset ?
                    {/* <b>{" " + screenIdToBeDeleted?.name}</b> app?. */}
                  </p>

                  <div className="mt-4 flex justify-end ">
                    <button
                      type="button"
                      className={classNames(
                        "",
                        // isPublic
                        //   ? "bg-red-600 text-white"
                        //   : "bg-green-600 text-gray-300 hover:bg-green-700 hover:text-white",
                        " inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      )}
                      onClick={onDeleteAssetCloseModal}
                      disabled={loading}
                    >
                      {"Cancel"}
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        "",
                        // isPublic
                        //   ? "bg-red-600 text-white"
                        //   : "bg-green-600 text-gray-300 hover:bg-green-700 hover:text-white",
                        "ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      )}
                      onClick={onDeletePress}
                      disabled={loading}
                    >
                      {loading && <LoadingIndicator />} {"Delete"}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteAssetModal;
