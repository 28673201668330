import axios from "axios";
import { LOGIN_CONSTANTS } from "../utils/Constants";
import { getVerifiedAccessToken } from "./credential";
export const URL = "https://nanoapp.dev";
// export const URL = "http://192.168.1.37";
export const REFRESH_URL = URL + "/web/jwt/refresh/?refresh=";
const getAccessToken = () => {
  return localStorage.getItem(LOGIN_CONSTANTS.ACCESS);
};
const getRefreshToken = () => {
  return localStorage.getItem(LOGIN_CONSTANTS.REFRESH);
};

export const getIsLoggedIn = () => {
  return localStorage.getItem(LOGIN_CONSTANTS.IS_LOGGED_IN);
};
export const storeIsLoggedIn = ({ value }) => {
  localStorage.setItem(LOGIN_CONSTANTS.IS_LOGGED_IN, value);
};
export const storeAccessToken = ({ access_token }) => {
  localStorage.setItem(LOGIN_CONSTANTS.ACCESS, access_token);
};
export const storeRefreshToken = ({ refresh_token }) => {
  localStorage.setItem(LOGIN_CONSTANTS.REFRESH, refresh_token);
};
export const getUserObjFromStorage = () => {
  return localStorage.getItem(LOGIN_CONSTANTS.USER_INFO_OBJ);
};
export const storeUserObj = ({ value }) => {
  localStorage.setItem(LOGIN_CONSTANTS.USER_INFO_OBJ, value);
};

export const getUpToDateAccessToken = async () => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  return await getVerifiedAccessToken(access, refresh, REFRESH_URL);
};

export const loginToNanoWeb = async ({ email }) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };
  const url = URL + "/web/login/";
  const body = email != null ? { email } : null;
  return axios({
    method: "post",
    url: url,
    data: body,
    headers: headers,
    timeout: 10000,
  })
    .then((response) => {
      if (response.status == 200 && response.data != null) {
        storeAccessToken({ access_token: response.data.access });
        storeRefreshToken({ refresh_token: response.data.refresh });
        storeIsLoggedIn({ value: "1" });

        return response.data;
      }
    })
    .catch((e) => {
      console.log("errrrrr", e);

      return new Promise.reject(e);
    });
};

export const getAppCodeByIdentifier = async (
  identifier = null,
  is_new = false
) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/get_app_code/";

  const body = {};

  if (identifier != null) {
    body["identifier"] = identifier;
  }
  if (is_new != null) {
    body["is_new"] = is_new;
  }

  return axios({
    method: "post",
    url: url,
    data: body,
    headers: headers,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("errrrrr", e);
    });
};

export const getAvailableApps = async ({ pageNum = 1, searchString }) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url =
    URL +
    "/web/get_apps/?" +
    "page=" +
    pageNum +
    "&page_size=8" +
    "&search=" +
    searchString;

  return axios({
    method: "get",
    url: url,
    headers: headers,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("errrrrr", e);
    });
};

export const saveAppCode = async ({
  identifier,
  name,
  code,
  json_code,
  status,
}) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/save_app_code/";

  const body = {
    identifier,
    name,
    code,
    json_code,
    status,
  };

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: body,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("errrrrr", e);
    });
};

export const getAppCredentials = async (identifier) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/get_app_credentials/" + identifier + "/";

  return axios({
    method: "get",
    url: url,
    headers: headers,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("errrrrr credentials", e);
    });
};

export const getAppPublicStatus = async (identifier) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/app_public_status/" + identifier + "/";

  return axios({
    method: "get",
    url: url,
    headers: headers,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("get public catch erro", e);
    });
};

export const postAppPublicStatus = async (identifier, is_public) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
    "Content-Type": "application/json",
  };

  const url = URL + "/web/app_public_status/" + identifier + "/";
  const body = {
    public: is_public,
  };

  return axios
    .put(url, body, { headers })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("set public catch error", e);
    });
};

export const deleteApp = async (identifier) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
    "Content-Type": "application/json",
  };

  const url = URL + "/web/app_delete/" + identifier + "/";
  const body = {
    is_deleted: true,
  };

  return axios
    .put(url, body, { headers })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("set public catch error", e);
    });
};

export const getListOfAllScreens = async ({
  identifier,
  pageNum = 1,
  searchString = "",
}) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/get_screens/";
  const body = {
    identifier,
    search: searchString,
    page_size: 3,
    page: pageNum,
  };

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: identifier != null ? body : null,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("get list of all screens catch erro", e);
    });
};

export const getScreenCode = async ({
  identifier,
  screen_id,
  is_new = "false",
}) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/get_screen_code/";

  const body = {
    identifier,
    screen_id,
    is_new,
  };
  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: body,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("getScreenCode error", e, identifier, screen_id, is_new);
    });
};

export const saveScreenCode = async ({
  identifier,
  screen_id,
  package_name,
  json_code,
  status,
  name,
  code,
}) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/save_screen_code/";

  const body = {
    screen_id:
      screen_id !== null && typeof screen_id === "string" ? screen_id : "",
    identifier:
      identifier !== null && typeof identifier == "string" ? identifier : "",
    package_name,
    json_code,
    status,
    name,
    code,
  };

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: body,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("errrrrr", e);
    });
};
export const deleteSreenByScreenId = async ({ screen_id }) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
    "Content-Type": "application/json",
  };

  const url = URL + "/web/screen_delete/" + screen_id + "/";
  const body = { is_deleted: true };
  return axios
    .put(url, JSON.stringify(body), { headers })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("delete screen catch error", e);
    });
};

export const setScreenAsFirst = async ({ screen_id }) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/screen_first/" + screen_id + "/";

  const body = {
    is_first: true,
  };

  return axios
    .put(url, body, { headers })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("screen first catch error", e);
    });
};

export const getTotalApp = async ({ identifier }) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/run_app/";

  const body = {
    identifier,
  };

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: body,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("errrrrr", e);
    });
};

export const getAllAssets = async ({
  identifier,
  asset_types,
  pageNum = 1,
}) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url =
    URL +
    "/web/assets/?identifier=" +
    identifier +
    "&asset_types=" +
    asset_types +
    "&page=" +
    pageNum +
    "&page_size=4";
  return axios({
    method: "get",
    url: url,
    headers: headers,
  })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("get all assets erro", e);
    });
};

export const uploadAssets = async ({
  identifier,
  asset_type,
  file,
  onUploadProgress,
  cropArea,
}) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const formData = new FormData();
  formData.append("file", file);
  formData.append("asset_type", asset_type);
  formData.append("identifier", identifier);
  if (asset_type == "launcher_icons") {
    formData.append("x", cropArea.x);
    formData.append("y", cropArea.y);
    formData.append("h", cropArea.height);
    formData.append("w", cropArea.width);
  }
  return axios
    .post(URL + "/web/upload_asset/", formData, {
      headers: {
        Authorization: await getVerifiedAccessToken(
          access,
          refresh,
          REFRESH_URL
        ),

        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        onUploadProgress(Math.round((100 * data.loaded) / data.total));
      },
    })
    .then((response) => {
      // handle the response
    })
    .catch((error) => {
      // handle errors
      console.log(error);
    });
};

export const deleteAsset = async ({ asset_id }) => {
  const access = await getAccessToken();
  const refresh = await getRefreshToken();

  const headers = {
    Authorization: await getVerifiedAccessToken(access, refresh, REFRESH_URL),
  };

  const url = URL + "/web/delete_asset/" + asset_id + "/";

  const body = {
    // is_first: true,
  };

  return axios
    .put(url, body, { headers })
    .then((response) => {
      if (response.status == 200) {
        return response.data;
      }
    })
    .catch((e) => {
      console.log("delete asset catch error", e);
    });
};
