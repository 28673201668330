export const LOGIN_CONSTANTS = {
  ACCESS: "access",
  REFRESH: "refresh",
  IS_LOGGED_IN: "is_logged_in",
  USER_INFO_OBJ: "user_info_obj",
};

export const CODE_STORE = {
  DRAFT: "draft",
  PUBLISH: "published",
};

export const loadingScreenCode = `
const loader = {
  component: "activity_indicator",
  value: true,
};


const screen = {
  name: 'CountScreen',
  screen: {
    v1: [loader],
  },
  style: { flex: 1, justifyContent: 'center' },

  screenProps: { options: { headerShown: false } }

};
const screens = [screen];
`;

export const loadingScreenStringCOde = `[{"name":"CountScreen","screen":{"v1":[{"component":"activity_indicator","value":true}]},"style":{"flex":1,"justifyContent":"center"},"screenProps":{"options":{"headerShown":false}}}]`;
