import React, { Fragment, useEffect, useRef, useState } from "react";
import { getAvailableApps } from "../../network/Network";
import { Dialog, Transition } from "@headlessui/react";

import { ImBin2 } from "react-icons/im";
import InputField from "../InputField";

function ListAppModal({
  isModalOpen,
  closeModal,
  onAppClick,
  onDeleteAppSelect,
}) {
  const [appsArray, setAppsArray] = useState([]);
  const [searchString, setSearchString] = useState("");

  const canFetchNextPageRef = useRef(false);
  const pageNumRef = useRef(1);

  const listAllApps = (search = "") => {
    getAvailableApps({
      pageNum: pageNumRef.current,
      searchString: search,
    })
      .then((re) => {
        if (re && re["results"] != null && re["results"].length >= 0) {
          // setAppsArray(re["results"]);
          if (canFetchNextPageRef.current && searchString.length == 0) {
            const curr = appsArray;

            setAppsArray([...curr, ...re["results"]]);
          } else {
            setAppsArray(re["results"]);
          }
          // setScreensList(s);

          if (re["results"].length == 8) {
            canFetchNextPageRef.current = true;
          } else {
            canFetchNextPageRef.current = false;
          }
        }
      })
      .catch(() => {
        console.log("listing apps error");
      });
  };

  function afterOpenModal() {
    listAllApps();
  }

  //   function closeModal() {
  //     setIsOpen(false);
  //   }
  useEffect(() => {
    if (isModalOpen) {
      afterOpenModal();
    }
  }, [isModalOpen]);
  const onListAppsScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;

    // e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && canFetchNextPageRef.current) {
      const cur = pageNumRef.current;
      pageNumRef.current = cur + 1;
      listAllApps();
    }
  };

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Select an app to open
                </Dialog.Title>
                {/* <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Your payment has been successfully submitted. We’ve sent you
                    an email with all of the details of your order.
                  </p>
                </div> */}

                <div
                  onScroll={onListAppsScroll}
                  className="mt-4 flex-col  h-[76vh]   overflow-y-scroll "
                >
                  <input
                    type="text"
                    autoComplete="given-name"
                    value={searchString}
                    onChange={(event) => {
                      setSearchString(event.target.value);
                      if (
                        event.target.value != null &&
                        event.target.value.length > 0
                      ) {
                        canFetchNextPageRef.current = false;
                        pageNumRef.current = 1;
                        listAllApps(event.target.value);
                      } else {
                        listAllApps("");
                      }
                    }}
                    placeholder={"Search..."}
                    className={
                      "w-[90%] mb-2 outline-none placeholder:text-xs py-1  shadow-none focus:ring-0 block   pl-2  rounded-sm border-0  text-black   placeholder:text-gray-400  sm:text-sm sm:leading-6  "
                    }
                  />

                  {appsArray != null && appsArray.length > 0 ? (
                    appsArray.map((appInfo) => {
                      return (
                        <div className=" mt-3 bg-blue-100 rounded-md py-0 flex items-center h-12  pr-4 ">
                          <button
                            type="button"
                            className=" flex-1 rounded-md border border-transparent  py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => {
                              onAppClick(appInfo);
                            }}
                          >
                            {appInfo.name}
                          </button>
                          <ImBin2
                            onClick={() => {
                              onDeleteAppSelect(appInfo);
                            }}
                            size={20}
                            color={"#3b82f6"}
                            className="cursor-pointer mx-2    hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                          />
                        </div>
                      );
                    })
                  ) : (
                    <p>Fetching</p>
                  )}
                  {/* <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Got it, thanks!
                  </button> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ListAppModal;
