// import React, { useEffect, useState } from "react";

// const SvgInline = ({ item }) => {
//   return <img alt="" src={item.file} width={500} height={500} />;
// };
// export default SvgInline;
import React, { useEffect, useState } from "react";
import { BiX } from "react-icons/bi";
import { classNames } from "../../utils/Utitlities";

const SvgInline = ({
  item,
  height,
  width,
  onDeleteClick,
  onAssetClick,
  showBorder = true,
}) => {
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  function replaceHeightAttribute(inputString, newHeight, newWidth) {
    // Define a regular expression to find the height attribute
    const heightRegex = /height="([^"]*)"/;

    // Use replace function to replace the height attribute with the new value
    const modifiedString = inputString.replace(
      heightRegex,
      `height="${newHeight}"`
    );
    const widthRegex = /width="([^"]*)"/;
    const modifiedString2 = modifiedString.replace(
      widthRegex,
      `width="${newWidth}"`
    );

    return modifiedString2;
  }
  useEffect(() => {
    fetch(item.file)
      .then((res) => {
        return res.text();
      })
      .then((d) => {
        const originalSvg = replaceHeightAttribute(d, height, width);

        setSvg(originalSvg);
        setIsLoaded(true);
      })
      .catch((e) => {
        console.log("SVG FETCH ERROR", e);

        setIsErrored(e);
      });
  }, [item.url]);
  return (
    <div
      onClick={
        onAssetClick
          ? () => {
              onAssetClick(item);
            }
          : null
      }
      className={classNames(
        showBorder ? "border-[0.5px]" : "",
        "  flex items-center justify-center cursor-pointer w-[130px] mt-2 ml-[7px] mr-[7px] h-[130px] p-0 relative group border-gray-400  rounded-md "
      )}
    >
      <div
        style={{ fontSize: 25 }}
        className={` svgInline svgInline--${isLoaded ? "loaded" : "loading"} ${
          isErrored ? "svgInline--errored" : ""
        }`}
        dangerouslySetInnerHTML={{ __html: svg }}
      />
      {onDeleteClick && (
        <BiX
          size={26}
          color="red"
          onClick={(event) => {
            event.stopPropagation();
            onDeleteClick(item);
          }}
          className="cursor-pointer  absolute top-0 right-0 hidden group-hover:block"
        />
      )}
    </div>
  );
};
export default SvgInline;
