import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { getAppPublicStatus, postAppPublicStatus } from "../../network/Network";
import { classNames } from "../../utils/Utitlities";
import { LoadingIndicator } from "../LoadingIndicator";

function PublicAppModal({
  showPublicAppModal,
  closePublicAppModal,
  identifier,
}) {
  const [isPublic, setIsPublic] = useState(false);
  const [loading, setLoading] = useState(false);

  const onIsPublicChange = (v) => {
    if (identifier) {
      // setIsPublic(v);
      setLoading(true);
      postAppPublicStatus(identifier, v)
        .then((res) => {
          if (res) {
            setIsPublic(res.public);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const getPublicStatus = () => {
    if (identifier) {
      getAppPublicStatus(identifier)
        .then((res) => {
          if (res) {
            setIsPublic(res.public);
          }
        })
        .catch(() => {});
    }
  };
  useEffect(() => {
    if (showPublicAppModal) {
      getPublicStatus();
    }
  }, [showPublicAppModal]);
  return (
    <Transition appear show={showPublicAppModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closePublicAppModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Make Public
                </Dialog.Title>
                <div className="mt-2 items-center ">
                  <p className="text-sm text-gray-500">
                    You can make this app public and share it to others.
                  </p>
                  {isPublic && <br />}

                  {isPublic && (
                    <p className="text-sm text-gray-500">Shareable link</p>
                  )}
                  {isPublic && (
                    // <CodeBlock language={"language-javascript"}>
                    //   {window.location.href}
                    // </CodeBlock>
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      className="underline text-blue-600 "
                      href={window.location.href}
                    >
                      {window.location.href}
                    </a>
                  )}

                  <div className="mt-4 flex justify-between ">
                    <button
                      type="button"
                      className={classNames(
                        "",
                        // isPublic
                        //   ? "bg-red-600 text-white"
                        //   : "bg-green-600 text-gray-300 hover:bg-green-700 hover:text-white",
                        "inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      )}
                      onClick={() => {
                        onIsPublicChange(!isPublic);
                      }}
                      disabled={loading}
                    >
                      {isPublic ? "Make Private" : "Make public"}
                    </button>
                    {loading && <LoadingIndicator />}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default PublicAppModal;
