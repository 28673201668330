import { create } from "zustand";

export const useIsLoggedIn = create((set) => ({
  isLoggedIn: false,

  setIsLoggedIn: async (v) => {
    set({ isLoggedIn: v });
  },
}));

export const useScreensList = create((set) => ({
  screensList: [],
  setScreensList: (v) => set({ screensList: v }),
}));

export const useCodeInfo = create((set) => ({
  codeInfo: null,
  setCodeInfo: (v) => set({ codeInfo: v }),
}));

export const useUserInfo = create((set) => ({
  userInfo: null,
  setUserInfo: (v) => set({ userInfo: v }),
}));
export const useSetProjectName = create((set) => ({
  projectName: null,
  setProjectName: (v) => set({ projectName: v }),
}));

export const useShowScreens = create((set) => ({
  showScreens: false,
  setShowScreens: (v) => set({ showScreens: v }),
}));
export const useShowIframe = create((set) => ({
  showIframe: false,
  setShowIframe: (v) => set({ showIframe: v }),
}));

export const useRunTotalAppInfo = create((set) => ({
  runTotalAppInfo: null,
  setRunTotalAppInfo: (v) => set({ runTotalAppInfo: v }),
}));

export const useScreenOptionsShow = create((set) => ({
  showScreenOptions: false,
  setShowScreenOptions: (v) => set({ showScreenOptions: v }),
}));

export const useClickScreenOptionsInfo = create((set) => ({
  clickedScreenOptionsInfo: false,
  setClickedScreenOptionsInfo: (v) => set({ clickedScreenOptionsInfo: v }),
}));
export const useShowSetAsFirstScreenModal = create((set) => ({
  showSetAsFirstScreenModal: false,
  setShowSetAsFirstScreenModal: (v) => set({ showSetAsFirstScreenModal: v }),
}));

export const useShowLogInPopup = create((set) => ({
  showLoginPopup: false,
  setShowLoginPopup: (v) => set({ showLoginPopup: v }),
}));
export const useAppIdentifer = create((set) => ({
  appIdentifier: null,
  setAppIdentifer: (v) => set({ appIdentifier: v }),
}));

export const useReloadScreensList = create((set) => ({
  reloadSreensList: false,
  setReloadScreensList: (v) => v.reloadSreensList,
}));

export const useShowLogoutModal = create((set) => ({
  showLogoutModal: false,

  setShowLogoutModal: async (v) => {
    set({ showLogoutModal: v });
  },
}));

export const useShowMediaModal = create((set) => ({
  showMediaModal: false,

  setShowMediaModel: async (v) => {
    set({ showMediaModal: v });
  },
}));

export const useShowDeleteAssetModal = create((set) => ({
  showDeleteAssetModal: null,

  setShowDeleteAssetModel: (v) => {
    set({ showDeleteAssetModal: v });
  },
}));

export const useShouldReloadAssetsData = create((set) => ({
  shouldReloadAssetsData: false,

  setShouldReloadAssetsData: (v) => {
    set({ shouldReloadAssetsData: v });
  },
}));

export const useShouldShowAssetsDetailsModal = create((set) => ({
  shouldShowAssetsDetailModal: false,

  setShouldShowAssetsDetailModal: (v) => {
    set({ shouldShowAssetsDetailModal: v });
  },
}));

export const useClickAssetDetails = create((set) => ({
  clickedAssetDetails: null,

  setClickedAssetDetails: (v) => {
    set({ clickedAssetDetails: v });
  },
}));

export const useShouldShowAppLogoCropViewerModal = create((set) => ({
  shouldShowAppLogoCropViewerModal: null,

  setShouldShowAppLogoCropViewerModal: (v) => {
    set({ shouldShowAppLogoCropViewerModal: v });
  },
}));

export const useCodeSaveStatus = create((set) => ({
  codeSaveStatus: "",

  setCodeSaveStatus: (v) => {
    set({ codeSaveStatus: v });
  },
}));

export const useSelectedScreenId = create((set) => ({
  selectedScreenId: null,

  setSelectedScreenId: (v) => {
    set({ selectedScreenId: v });
  },
}));

export const useIsUnLoggedInCodeEditing = create((set) => ({
  isUnLoggedInCodeEditing: false,

  setIsUnLoggedInCodeEditing: (v) => {
    set({ isUnLoggedInCodeEditing: v });
  },
}));

export const useIsReloadScreenList = create((set) => ({
  isReloadScreensList: false,

  setIsReloadScreensList: (v) => {
    set({ isReloadScreensList: v });
  },
}));

export const useIsSearchScreens = create((set) => ({
  isSearchScreens: false,

  setIsSearchScreens: (v) => {
    set({ isSearchScreens: v });
  },
}));
