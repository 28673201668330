import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";

import { classNames } from "../utils/Utitlities";
import { getCroppedImg } from "./modals/CanvasUtils";
const CROP_AREA_ASPECT = 1 / 1;

const Output = ({ croppedArea, image }) => {
  const scale = 100 / croppedArea.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto",
  };

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  };

  return (
    <div
      className={` relative  h-[350px] w-[100px] overflow-hidden  `}
      style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
    >
      <div className="  h-[100px] p-0 w-[100px] border-gray-500 border-[0.5px] bg-white  overflow-hidden absolute top-0 ">
        <img
          src={image}
          className="  origin-top-left "
          alt=""
          style={imageStyle}
        />
      </div>

      <div className="  h-[100px] p-0 w-[100px] border-gray-500 border-[0.5px] bg-white  overflow-hidden rounded-full absolute top-[110px] ">
        <img
          src={image}
          className={`  m-0 origin-top-left `}
          alt=""
          style={{ ...imageStyle, height: "auto" }}
        />
      </div>
      <div className="  h-[100px] p-0 w-[100px] border-gray-500 border-[0.5px] bg-white  overflow-hidden rounded-2xl absolute top-[220px] ">
        <img
          src={image}
          className={`  m-0 origin-top-left `}
          alt=""
          style={{ ...imageStyle, height: "auto" }}
        />
      </div>
    </div>
  );
};
const ImageCropper = ({
  file,
  onMediaModalClose,
  onUploadLauncherIconPress,
}) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    setCroppedImage(null);
  };
  const onMount = async () => {
    let imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);
  };
  useEffect(() => {
    onMount();
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file2 = e.target.files[0];
      let imageDataUrl = await readFile(file2);

      setImageSrc(imageDataUrl);
    }
  };

  const onPhotoSelected = async () => {
    onUploadLauncherIconPress(file, croppedArea);
  };
  return (
    <div className=" h-[90%]  ">
      {imageSrc ? (
        <React.Fragment>
          <div className="flex h-[90%] align-middle items-center ">
            <div className="relative h-[320px]   w-[50%]">
              <Cropper
                image={imageSrc}
                aspect={CROP_AREA_ASPECT}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                showGrid={true}
                cropShape="rect"
                objectFit="contain"
                zoomSpeed={1}
                restrictPosition={true}
                onCropAreaChange={setCroppedArea}
              />
            </div>
            <div className="relative h-[320px]   justify-center  flex  w-[50%]   ">
              {croppedArea && (
                <Output image={imageSrc} croppedArea={croppedArea} />
              )}
            </div>
          </div>
          {croppedArea != null && (
            <div>
              <p> {`x :${croppedArea.x}`} </p>
              <p> {` y :${croppedArea.y}`} </p>
              <p> {`height :${croppedArea.height}`} </p>
              <p> {` width :${croppedArea.width}`} </p>
            </div>
          )}
          <div className="flex justify-end mt-4 pr-14  ">
            <button
              type="button"
              className={classNames(
                "",
                // isPublic
                //   ? "bg-red-600 text-white"
                //   : "bg-green-600 text-gray-300 hover:bg-green-700 hover:text-white",
                " inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              )}
              onClick={onMediaModalClose}
              // disabled={loading}
            >
              {"Cancel"}
            </button>
            <button
              type="button"
              className={classNames(
                "",
                // isPublic
                //   ? "bg-red-600 text-white"
                //   : "bg-green-600 text-gray-300 hover:bg-green-700 hover:text-white",
                "ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              )}
              onClick={onPhotoSelected}
              // disabled={loading}
            >
              {/* {loading && <LoadingIndicator />}  */}
              {"Upload"}
            </button>
          </div>
        </React.Fragment>
      ) : (
        <input type="file" onChange={onFileChange} accept="image/*" />
      )}
    </div>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}


export default ImageCropper;
