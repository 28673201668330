import { useState } from "react";
import DrawerItemView from "../itemviews/PermDrawerItemView";
import { classNames } from "../../utils/Utitlities";

import {
  IoCodeSlashOutline,
  IoDocument,
  IoDocumentAttachOutline,
  IoDocumentTextOutline,
  IoDocumentTextSharp,
  IoExtensionPuzzleOutline,
  IoFileTrayStackedOutline,
  IoLogoGithub,
  IoPhonePortraitOutline,
} from "react-icons/io5";
import { useShowScreens } from "../Zustand";
const ICONS_SIZE = 24;
const Drawer = ({
  onDesignClick,
  onAssetsClick,
  onAPIPress,
  onPluginsPress,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const showScreens = useShowScreens((state) => state.showScreens);

  const getIconAsPerName = ({ selected, index }) => {
    switch (index) {
      case 0:
        return (
          <IoPhonePortraitOutline
            className="text-gray-500 group-hover:text-white "
            color={selected ? "white" : ""}
            size={ICONS_SIZE}
          />
        );
      case 1:
        return (
          <IoFileTrayStackedOutline
            className="text-gray-500 group-hover:text-white "
            color={selected ? "white" : ""}
            size={ICONS_SIZE}
          />
        );
      case 2:
        return (
          <IoExtensionPuzzleOutline
            className="text-gray-500 group-hover:text-white "
            color={selected ? "white" : ""}
            size={ICONS_SIZE}
          />
        );
      case 3:
        return (
          <IoCodeSlashOutline
            className="text-gray-500 group-hover:text-white "
            color={selected ? "white" : ""}
            size={ICONS_SIZE}
          />
        );

      default:
        break;
    }
  };
  const DrawerArray = [
    {
      name: "Screens",
      icon: null,
      onPress: onDesignClick,
    },
    {
      name: "Assets",
      icon: null,
      onPress: onAssetsClick,
    },

    {
      name: "Plugins",
      icon: null,
      onPress: onPluginsPress,
    },
    {
      name: "Api",
      icon: null,
      onPress: onAPIPress,
    },
  ];

  return (
    <div
      className={classNames(
        "w-[5%] pl-0 pr-0  h-[calc(100vh-60px)]  flex flex-col items-center bg-gray-700  ",
        showScreens ? "max-sm:min-w-[22%]  " : " max-sm:hidden"
      )}
    >
      {DrawerArray.map((item, index) => {
        const onPress = () => {
          if (index == 0) {
            setSelectedIndex(index);
          }
          item.onPress();
        };
        return (
          <DrawerItemView
            Name={item.name}
            onClick={onPress}
            index={index}
            selected={selectedIndex == index}
            icon={getIconAsPerName({ index, selected: selectedIndex == index })}
            topRightRounded={index >= 1 && selectedIndex === index - 1}
            bottomRightRounded={selectedIndex === index + 1}
          />
        );
      })}

      <div
        className={classNames(
          selectedIndex == DrawerArray.length - 1 ? "rounded-tr-xl" : "",
          "flex-1 bg-gray-900  w-full "
        )}
      ></div>
      <DrawerItemView
        Name={"Docs"}
        onClick={() => {
          window.open("http://docs.nanoapp.dev/", "_blank");
        }}
        // index={2}
        // selected={false}
        icon={
          <IoDocumentTextOutline
            className=" self-center text-gray-500 "
            color={""}
            size={ICONS_SIZE}
          />
        }
        topRightRounded={false}
        bottomRightRounded={false}
      />
      <DrawerItemView
        Name={"Github"}
        onClick={() => {
          window.open(
            "https://github.com/sandarshnaroju/react-native-nano",
            "_blank"
          );
        }}
        // index={index}
        // selected={selectedIndex == index}
        icon={
          <IoLogoGithub
            className=" self-center text-gray-500"
            // color={"white"}
            size={ICONS_SIZE}
          />
        }
        topRightRounded={false}
        bottomRightRounded={false}
      />
    </div>
  );
};

export default Drawer;
