import { Editor } from "@monaco-editor/react";

import {
  classNames,
  formatDocument,
  getJsonCodeFromCode,
  isJsonCodeContainsAllScreenData,
  replaceUrlByProjectIdentifier,
  stringifyScreensArrayandSendToIframe,
} from "../utils/Utitlities";
import ScreensList from "./sidebar/ScreensList";
import {
  useAppIdentifer,
  useCodeInfo,
  useCodeSaveStatus,
  useIsLoggedIn,
  useIsSearchScreens,
  useIsUnLoggedInCodeEditing,
  useScreensList,
  useSelectedScreenId,
  useSetProjectName,
  useShowIframe,
  useShowScreens,
} from "./Zustand";
import { useEffect, useRef, useState } from "react";
import { getScreenCode } from "../network/Network";
import { CODE_STORE } from "../utils/Constants";
import { LoadingIndicator } from "./LoadingIndicator";

const NanoEditor = ({
  onCodeChange,

  editorRef,

  onDeleteScreenClick,

  updateCodeRefAndCodeJSONRef,
}) => {
  const [isFetchingCode, setIsFetchingCode] = useState(false);
  const monacoRef = useRef(null);
  const codeInfo = useCodeInfo((state) => state.codeInfo);
  const setCodeInfo = useCodeInfo((state) => state.setCodeInfo);
  const zusScreensList = useScreensList((state) => state.screensList);
  const setScreensList = useScreensList((state) => state.setScreensList);
  const setIsUnLoggedInCodeEditing = useIsUnLoggedInCodeEditing(
    (state) => state.setIsUnLoggedInCodeEditing
  );

  const appIdentifier = useAppIdentifer((state) => state.appIdentifier);
  const setAppIdentifer = useAppIdentifer((state) => state.setAppIdentifer);
  const setIsSearchScreens = useIsSearchScreens(
    (state) => state.setIsSearchScreens
  );
  const isSearchScreens = useIsSearchScreens((state) => state.isSearchScreens);
  const setSelectedScreenId = useSelectedScreenId(
    (state) => state.setSelectedScreenId
  );
  const isLoggedIn = useIsLoggedIn((state) => state.isLoggedIn);
  const setCodeSaveStatus = useCodeSaveStatus(
    (state) => state.setCodeSaveStatus
  );

  const selectedScreenId = useSelectedScreenId(
    (state) => state.selectedScreenId
  );

  const showScreens = useShowScreens((state) => state.showScreens);
  const showIframe = useShowIframe((state) => state.showIframe);
  const setProjectName = useSetProjectName((state) => state.setProjectName);

  let onLoadFormatTimeOut = null;
  let formatCodeTimeOut = null;

  function handleEditorChange(value, event) {
    if (monacoRef.current) {
      const errors = monacoRef.current.editor.getModelMarkers();
      // Hint = 1,Info = 2,   Warning = 4,   Error = 8,
      const actualErrorObj = errors.find(
        (errObj) => errObj.severity === monacoRef.current.MarkerSeverity.Error
      );

      if (actualErrorObj == null) {
        const code_json = getJsonCodeFromCode(value);

        if (code_json) {
          let parsed = null;
          try {
            parsed = JSON.parse(code_json);
          } catch (error) {
            console.log("jsonparse error", error);
          }

          if (parsed && isJsonCodeContainsAllScreenData(parsed)) {
            if (editorRef.current) {
              if (formatCodeTimeOut) {
                clearTimeout(formatCodeTimeOut);
              }
              formatCodeTimeOut = setTimeout(() => {
                formatDocument(editorRef.current);
              }, 4000);
            }

            stringifyScreensArrayandSendToIframe(parsed);

            onCodeChange({ currentCode: value, currentCodeJson: code_json });
          }
        }
      }
    }
  }

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    monacoRef.current = monaco;
    if (editorRef.current) {
      if (onLoadFormatTimeOut) {
        clearTimeout(onLoadFormatTimeOut);
      }
      onLoadFormatTimeOut = setTimeout(() => {
        formatDocument(editorRef.current);
      }, 200);
    }
  }

  function handleEditorWillMount(monaco) {
    // monaco.
  }

  function handleEditorValidation(markers) {
    // model markers

    markers.forEach((marker) =>
      console.log("onValidate:", marker.message, marker.severity)
    );
    if (markers && markers.length == 0) {
    }
  }

  const fetchCodeAndDisplay = ({ identifier, screen_id, is_new = false }) => {
    setIsFetchingCode(true);
    getScreenCode({ identifier, screen_id, is_new })
      .then((r) => {
        if (r) {
          if (appIdentifier == null) {
            replaceUrlByProjectIdentifier(r.identifier);
          }

          const code_json = getJsonCodeFromCode(r.code);

          updateCodeRefAndCodeJSONRef({
            currentCode: r.code,
            currentCodeJson: code_json,
          });
          stringifyScreensArrayandSendToIframe(JSON.parse(code_json));

          setProjectName(r.name);
          if (isLoggedIn) {
            if (r != null && r.status === CODE_STORE.PUBLISH) {
              setCodeSaveStatus("Published");
            } else {
              setCodeSaveStatus("Draft saved");
            }
          }
          setCodeInfo(r);
          setIsFetchingCode(false);
          setIsSearchScreens(false);
        }
      })
      .catch((e) => {
        console.log(" nanoeditor new screen create error");
      });
  };
  const checkWhetherSelectedScreenIdBelongsToScreensList = () => {
    return zusScreensList.find((t) => t.id == selectedScreenId) != null
      ? true
      : false;
  };

  useEffect(() => {
    if (
      selectedScreenId != null &&
      checkWhetherSelectedScreenIdBelongsToScreensList()
    ) {
      const argObj = { screen_id: selectedScreenId };
      if (appIdentifier) {
        argObj["identifier"] = appIdentifier;
      }
      fetchCodeAndDisplay(argObj);
    } else {
      if (zusScreensList != null && zusScreensList[0] != null) {
        if (zusScreensList[0].id != null) {
          const argObj = {
            screen_id: isSearchScreens
              ? selectedScreenId
              : zusScreensList[0].id,
          };
          if (appIdentifier) {
            argObj["identifier"] = appIdentifier;
          }
          fetchCodeAndDisplay(argObj);
        } else {
          setIsUnLoggedInCodeEditing(false);
          fetchCodeAndDisplay({});
        }
      } else {
      }
    }
  }, [zusScreensList, selectedScreenId, appIdentifier]);

  return (
    <div className="flex relative flex-row h-[calc(100vh-60px)] w-[70%] max-sm:w-full  ">
      <ScreensList onDeleteScreenClick={onDeleteScreenClick} />

      <Editor
        height={"h-[calc(100vh-60px)]"}
        className={classNames(
          showIframe ? "max-sm:hidden" : " ",
          "max-sm:w-full w-[82%] ",
          showScreens ? "max-sm:w-[72%]" : ""
        )}
        defaultLanguage="javascript"
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        beforeMount={handleEditorWillMount}
        onValidate={handleEditorValidation}
        value={codeInfo != null ? codeInfo.code : ""}
        theme={"vs-dark"}
      />
      {isFetchingCode ? (
        <div className="absolute top-[50%] left-[50%] ">
          {" "}
          <LoadingIndicator />{" "}
        </div>
      ) : null}
    </div>
  );
};

export default NanoEditor;
