import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { getTotalApp } from "../../network/Network";
import {
  checkAlphaNumDash,
  getIdentifierFromUrl,
} from "../../utils/Utitlities";
import { useRunTotalAppInfo } from "../Zustand";
import AppFrame from "../totalapp/AppFrame";

function RunAppModal() {
  const [totalAppInfo, setTotalAppInfo] = useState(null);
  const runTotalAppInfo = useRunTotalAppInfo((state) => state.runTotalAppInfo);
  const setRunTotalAppInfo = useRunTotalAppInfo(
    (state) => state.setRunTotalAppInfo
  );
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    const identifier = getIdentifierFromUrl();

    if (identifier && checkAlphaNumDash(identifier + "")) {
      getTotalApp({ identifier })
        .then((re) => {
          // setTotalAppInfo(re);
          setTotalAppInfo({
            json: re.json,
          });
        })
        .catch(() => {});
    } else {
      setTotalAppInfo({
        json: runTotalAppInfo,
      });
    }
  }
  useEffect(() => {
    if (runTotalAppInfo != null) {
      afterOpenModal();
    }
  }, [runTotalAppInfo]);

  const closeInstructionsModal = () => {
    setRunTotalAppInfo(null);
  };

  return (
    <Transition appear show={runTotalAppInfo != null} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={closeInstructionsModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed  inset-0  ">
          <div className="flex min-h-full   justify-center p-4 text-center  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="  max-w-md transform  rounded-2xl bg-white m-0 p-0   shadow-xl transition-all">
                <AppFrame totalCode={totalAppInfo?.json} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default RunAppModal;
