import { Editor } from "@monaco-editor/react";

import { useState } from "react";
import { formatDocument } from "../utils/Utitlities";

const MonacoCodeBlock = ({ code }) => {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [codeValue, setCodeValue] = useState(code);
  let onLoadFormatTimeOut = null;

  function handleEditorChange(value, event) {
    setCodeValue(value);
  }

  function handleEditorDidMount(editor, monaco) {
    if (editor) {
      if (onLoadFormatTimeOut) {
        clearTimeout(onLoadFormatTimeOut);
      }
      onLoadFormatTimeOut = setTimeout(() => {
        formatDocument(editor);
        setTimeout(() => {
          setIsReadOnly(true);
        }, 200);
      }, 200);
    }
  }

  function handleEditorWillMount(monaco) {
    // monaco.
  }

  function handleEditorValidation(markers) {}

  return (
    <div className="rounded-md h-[280px]  overflow-hidden">
      <Editor
        height={"280px"}
        defaultLanguage="javascript"
        className="rounded-xl my-custom-editor"
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        beforeMount={handleEditorWillMount}
        onValidate={handleEditorValidation}
        value={code != null ? "\n \n" + code : ""}
        theme={"vs-dark"}
        options={{
          readOnly: isReadOnly,
          lineNumbers: "off",
          scrollbar: { vertical: "hidden", horizontal: "hidden" },
          minimap: { enabled: false },
          contextmenu: false,
          scrollBeyondLastLine: false,
          domReadOnly: true,
          stablePeek: true,
          revealHorizontalRightPadding: 0,
          padding: { top: 0, bottom: 0 },
        }}
      />
    </div>
  );
};

export default MonacoCodeBlock;
