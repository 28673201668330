import { useEffect } from "react";
import { BiX } from "react-icons/bi";

const FontsItemView = ({ item, onDeleteClick, onAssetClick }) => {
  useEffect(() => {
    // Load font asynchronously
    if (item != null && item.type != null && item.type.includes("ttf")) {
      const font = new FontFace("uploadedFont" + item.id, `url(${item.file})`);
      font.load().then(() => {
        document.fonts.add(font);
        // Font is ready to use
      });
    }
  }, []);
  return (
    <div
      onClick={
        onAssetClick
          ? () => {
              onAssetClick(item);
            }
          : null
      }
      className="  items-center justify-center ml-[7px] mr-[7px] mt-2 w-[95%] cursor-pointer  p-0 mb-2  relative group border-gray-400 border-[0.5px] rounded-md  "
    >
      <p
        className="text-center pt-2 pb-2 h-30 text-4xl w-[100%]     "
        style={{ fontFamily: "uploadedFont" + item.id }}
      >
        {item.name}
      </p>
      {onDeleteClick && (
        <BiX
          size={26}
          color="red"
          onClick={(event) => {
            event.stopPropagation();
            onDeleteClick(item);
          }}
          className="cursor-pointer  absolute top-0 right-0 hidden group-hover:block"
        />
      )}
    </div>
  );
};
export default FontsItemView;
