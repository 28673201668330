import { useEffect } from "react";
import { BiX } from "react-icons/bi";

const AssetItemView = ({ item, onDeleteClick, onAssetClick }) => {
  return (
    <div
      onClick={() => {
        onAssetClick(item);
      }}
      className=" cursor-pointer  h-[130px] p-0 w-[130px] ml-[7px] mr-[7px] mb-2 mt-2 relative group border-gray-400 border-[0.5px] rounded-md  "
    >
      <img
        alt=""
        className=" m-0 p-0 max-w-[100%] max-h-[100%]     absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  "
        src={item.file}
      />

      <BiX
        size={26}
        color="red"
        onClick={(event) => {
          event.stopPropagation();
          onDeleteClick(item);
        }}
        className="cursor-pointer  absolute top-0 right-0 hidden group-hover:block"
      />
    </div>
  );
};
export default AssetItemView;
