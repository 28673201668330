import { useEffect, useRef, useState } from "react";
import { BiDotsHorizontalRounded, BiPlus } from "react-icons/bi";
import {
  checkAlphaNumDash,
  classNames,
  getIdentifierFromUrl,
} from "../../utils/Utitlities";
import {
  useAppIdentifer,
  useClickScreenOptionsInfo,
  useCodeInfo,
  useIsLoggedIn,
  useIsReloadScreenList,
  useIsSearchScreens,
  useScreenOptionsShow,
  useScreensList,
  useSelectedScreenId,
  useShowLogInPopup,
  useShowScreens,
} from "../Zustand";
import { getListOfAllScreens, getScreenCode } from "../../network/Network";
import { LoadingIndicator } from "../LoadingIndicator";

const ScreenThumbnail = ({
  onIframeLoad,
  iframeId,
  onDeleteClick,
  onScreenClick,
  isScreenSelected,
  onScreenOptionsClick,
  isFirst,
}) => {
  const urlIdentifier = getIdentifierFromUrl();
  return (
    <div
      onClick={onScreenClick}
      className={classNames(
        isScreenSelected ? "border-2 border-npurple  " : " ",
        "cursor-pointer flex items-center justify-center  bg-blue-100  relative rounded-md  bottom-1 mb-2 h-[184px]  w-[96px]   "
      )}
    >
      <div
        style={{
          height: "180px",
          width: "90px",
        }}
      >
        <iframe
          title="Nano-iframe"
          height={"180px"}
          width={"90px"}
          style={{
            pointerEvents: "none",
            transform: "scale(0.5)",
            WebkitTransform: "scale(0.5)",
            MozTransform: "scale(0.5)",
            transformOrigin: "0 0",
            zoom: 2,
            borderRadius: 6,
          }}
          id={iframeId}
          src={`https://www.nanoapp.dev/dashboard/preview/?app_id=${urlIdentifier}`}
          onLoad={onIframeLoad}
        ></iframe>
      </div>

      <BiDotsHorizontalRounded
        size={25}
        color={"white"}
        className="absolute top-0 right-0  cursor-pointer   bg-npurple rounded-bl-md rounded-tr-[1px]  "
        onClick={(e) => {
          e.stopPropagation();
          onScreenOptionsClick();
        }}
      />
      {isFirst && (
        <p className="absolute text-[11px] bg-gold right-0 pt-0 pl-2 pr-1 bottom-0 rounded-br-md rounded-tl-lg ">
          first
        </p>
      )}
    </div>
  );
};

function ScreensList({ onDeleteScreenClick }) {
  const pageNumRef = useRef(1);
  const screensListRef = useRef([]);
  const canFetchNextPageRef = useRef(false);
  const [isNewScreenCreating, setIsNewScreenCreating] = useState(false);
  const setCodeInfo = useCodeInfo((state) => state.setCodeInfo);

  const [searchString, setSearchString] = useState("");

  const setIsSearchScreens = useIsSearchScreens(
    (state) => state.setIsSearchScreens
  );
  const setSelectedScreenId = useSelectedScreenId(
    (state) => state.setSelectedScreenId
  );
  const selectedScreenId = useSelectedScreenId(
    (state) => state.selectedScreenId
  );
  const appIdentifier = useAppIdentifer((state) => state.appIdentifier);

  const zusScreensList = useScreensList((state) => state.screensList);
  const setScreensList = useScreensList((state) => state.setScreensList);

  const showScreens = useShowScreens((state) => state.showScreens);
  const setShowScreenOptions = useScreenOptionsShow(
    (state) => state.setShowScreenOptions
  );
  const setClickedScreenOptionsInfo = useClickScreenOptionsInfo(
    (state) => state.setClickedScreenOptionsInfo
  );
  const isLoggedIn = useIsLoggedIn((state) => state.isLoggedIn);
  const setShowLoginPopup = useShowLogInPopup(
    (state) => state.setShowLoginPopup
  );
  const isReloadScreensList = useIsReloadScreenList(
    (state) => state.isReloadScreensList
  );
  const setIsReloadScreensList = useIsReloadScreenList(
    (state) => state.setIsReloadScreensList
  );
  const onSearchStringChange = (searchString) => {
    pageNumRef.current = 1;
    canFetchNextPageRef.current = false;
    setIsSearchScreens(true);
    fetchScreenList(searchString);
  };

  const fetchScreenList = (searchString = "", isScroll = false) => {
    getListOfAllScreens({
      identifier: appIdentifier,
      pageNum: pageNumRef.current,
      searchString: searchString,
    })
      .then((s) => {
        if (s != null && s.length >= 0) {
          if (isScroll) {
            const curr = JSON.parse(JSON.stringify(screensListRef.current));

            screensListRef.current = [...curr, ...s];
          } else {
            screensListRef.current = s;
          }

          if (selectedScreenId == null) {
            setSelectedScreenId(s[0].id);
          }
          setScreensList(screensListRef.current);

          if (s != null && s.length > 0 && s.length % 3 == 0) {
            canFetchNextPageRef.current = true;
          } else {
            canFetchNextPageRef.current = false;
          }
          setIsNewScreenCreating(false);
          setIsReloadScreensList(false);
        }
      })
      .catch((s) => {
        console.log("GET ALL SCREENS ERROR ", s);
      });
  };

  useEffect(() => {
    if (appIdentifier) {
      fetchScreenList();
    }
  }, [appIdentifier]);
  useEffect(() => {
    if (isReloadScreensList) {
      fetchScreenList();
    }
  }, [isReloadScreensList]);

  const onNewScreenCreateClick = () => {
    if (isLoggedIn) {
      setIsNewScreenCreating(true);
      const urlIdentifier = getIdentifierFromUrl();
      getScreenCode({ identifier: urlIdentifier, is_new: true })
        .then((r) => {
          setSelectedScreenId(r.screen_id);
          fetchScreenList();
        })
        .catch((e) => {
          console.log("screen list new screen create error", e);
        });
    } else {
      setShowLoginPopup(true);
    }
  };
  const onScreenListScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;

    // e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && canFetchNextPageRef.current) {
      const cur = pageNumRef.current;
      pageNumRef.current = cur + 1;

      fetchScreenList(searchString, true);
    }
  };
  const fetchCodeAndDisplayWhenNoIdentifier = () => {
    getListOfAllScreens({})
      .then((s) => {
        if (s != null && s.length >= 0) {
          setSelectedScreenId(s[0].id);
          setScreensList(s);
          if (!isLoggedIn) {
            setCodeInfo(s[0]);
          }
        }
      })
      .catch((s) => {
        console.log("GET ALL SCREENS ERROR ", s);
      });
  };
  useEffect(() => {
    const urlIdentifier = getIdentifierFromUrl();

    if (
      urlIdentifier == null ||
      urlIdentifier == "" ||
      !checkAlphaNumDash(urlIdentifier + "")
    ) {
      fetchCodeAndDisplayWhenNoIdentifier();
    }
  }, []);
  return (
    <div
      className={classNames(
        showScreens ? "max-sm:min-w-[22%]  " : " max-sm:hidden",
        " flex flex-col items-center pt-2 bg-gray-700  h-[calc(100vh-60px)] w-[15%]   overflow-x-hidden   "
      )}
    >
      <button
        onClick={onNewScreenCreateClick}
        className="items-center mb-2  w-[96px]   justify-center relative flex rounded-sm bg-npurple text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
      >
        <span className="absolute -inset-1.5" />
        <span className="sr-only">View notifications</span>

        {isNewScreenCreating ? (
          <LoadingIndicator />
        ) : (
          <BiPlus size={25} color={"white"} className="pl-2" />
        )}

        <p
          className={
            "text-white hover:bg-gray-700 hover:text-white rounded-md pl-1 pr-3 py-2 text-sm font-medium"
          }
          aria-current={"page"}
        >
          {"Screen"}
        </p>
      </button>

      <input
        type="text"
        autoComplete="given-name"
        value={searchString}
        onChange={(event) => {
          setSearchString(event.target.value);
          onSearchStringChange(event.target.value);
        }}
        placeholder={"Search..."}
        className={
          " outline-none placeholder:text-xs py-1 bg-gray-800 shadow-none focus:ring-0 block w-[80%]  pl-2 mb-3 rounded-sm border-0  text-white   placeholder:text-gray-400  sm:text-sm sm:leading-6  "
        }
      />
      <div
        onScroll={onScreenListScroll}
        className=" overflow-y-auto pb-[100px] bg-gray-700 h-[90vh]   "
      >
        {zusScreensList.map((s) => {
          return (
            <ScreenThumbnail
              iframeId={"screenThumbnail-" + s.id}
              isScreenSelected={selectedScreenId == s.id}
              onIframeLoad={() => {
                const iframe = document.getElementById(
                  "screenThumbnail-" + s.id
                );
                if (iframe != null && iframe.contentWindow != null) {
                  if (s.json.indexOf("[") == 0) {
                    iframe.contentWindow.postMessage(
                      s.json,
                      "https://www.nanoapp.dev/dashboard/preview"
                    );
                  } else {
                    iframe.contentWindow.postMessage(
                      "[" + s.json + "]",
                      "https://www.nanoapp.dev/dashboard/preview"
                    );
                  }
                }
              }}
              key={s.id}
              onDeleteClick={() => {
                onDeleteScreenClick(s.id);
              }}
              isFirst={s.is_first}
              onScreenOptionsClick={() => {
                if (isLoggedIn) {
                  setClickedScreenOptionsInfo(s.id);

                  setShowScreenOptions(true);
                } else {
                  setShowLoginPopup(true);
                }
              }}
              onScreenClick={() => {
                setSelectedScreenId(s.id);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ScreensList;
