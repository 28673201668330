import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { getIsLoggedIn } from "../network/Network";
import { useIsLoggedIn } from "./Zustand";

const SignInButton = ({ responseMessage, userInfo, onLogOut }) => {
  const isLoggedIn = useIsLoggedIn((state) => state.isLoggedIn);
  const setIsLoggedIn = useIsLoggedIn((state) => state.setIsLoggedIn);

  const errorMessage = (error) => {
    console.log(error);
  };
  const onClick = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    googleLogout();
    onLogOut();
  };

  return (
    <div className="self-center  align-middle ">
      {isLoggedIn && userInfo != null && getIsLoggedIn() ? (
        <div
          onClick={onClick}
          className="flex items-center cursor-pointer border-gray-500 border-2 p-1 rounded-sm "
        >
          <img src={userInfo.picture} alt="" className="h-8 mr-2 rounded-md " />
          <p>{userInfo.name}</p>
        </div>
      ) : (
        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
      )}
    </div>
  );
};

export default SignInButton;
